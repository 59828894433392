import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, SortTypeEnum, TypeMediaFileEnum } from 'app/config/constants';
import { DialogConfirmSaleTicketComponent } from 'app/dialog/dialog-confirm-sale-ticket/dialog-confirm-sale-ticket.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { Image as ImageApplicaiton } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import { Ticket } from 'app/model/entity/ticket';
import { TicketDetail } from 'app/model/entity/ticket-detail';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreviewTabTicketComponent } from './preview-tab-ticket/preview-tab-ticket.component';

@Component({
  selector: 'ticket-editor-tab-ticket',
  templateUrl: './ticket-editor-tab-ticket.component.html',
  styleUrls: ['./ticket-editor-tab-ticket.component.scss']
})
export class TicketEditorTabTicketComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  @Input() languageTranslation: string[];
  @Input() informationAccount: any;
  @Input() companies: any;
  @Input() fileGlossary: any;
  @Input() mapImageEnvironmentVariables: Map<string, number>;
  @ViewChild(PreviewTabTicketComponent) previewComponent: PreviewTabTicketComponent;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); // array subscription
  tickets: Array<Ticket> = new Array<Ticket>(); // list ticket
  ticketDisplays: Array<Ticket> = new Array<Ticket>(); // list ticket display
  dataFilterOrderConvert: Array<Ticket> = new Array<Ticket>();
  ticketSelected: Ticket; // ticket Selected
  ticketSelectedClone: Ticket; // ticket Selected clone
  nameDisplayClone: string; // name display editing
  imgSrc: string = Constant.URL_IMG_DEFAULT_1; // url img default
  isEnlargePreview: boolean = false; // true if preview on
  isPlay: boolean; //true if play preview
  isEditTicketDetail: boolean = false; // returns true if editing ticket details
  isEditTicketDetailUpdate: boolean = false; // returns true if editing ticket details
  urlImage1: string;
  urlImage2: string;
  isPreview1: boolean;
  focusOn: string;
  companyId: string;
  config: any;
  titleFormData: string;
  titleEdit: string;
  titleEditSchedule: string;
  titleAdd: string;
  titleAddSchedule: string;
  titleDuplicate: string;
  image1: any;
  image2: any;
  languageSelected: string;
  time: any = '00:00';
  date: any;
  timezone: any;
  listApp: ApplicationDTO[] = []; // list Application
  ticketId: number;
  mainColor: string;
  saleTickets: [];
  isChooseDetailUpdate: boolean;

  /**
   * language key
   */
  public languageKey: string;
  public languageSelectedPreview: string;
  /**
   * commonObject
   */
  commonObject: Common;
  /**
   * Constant's component
   */
  private readonly FILE_MEDIA_OBJECT = 1;
  private readonly IMAGE_NAME_DROP_MEDIA = 'imageTE';
  private readonly FILE_ATTRIBUTE = 'file';
  private readonly NAME_ATTRIBUTE = 'name';
  private readonly URL_ATTRIBUTE = 'url';

  /**
   * displaySize
   */
  displaySize = [
    { title: 'iPhoneSE', width: '375px', height: '667px' },
    { title: 'iPhone14', width: '390px', height: '844px' },
    { title: 'iPhone14 Pro Max', width: '428px', height: '926px' },
    { title: 'iPhone13 mini', width: '375px', height: '812px' },
    { title: 'Galaxy A51', width: '412px', height: '914px' },
    { title: 'Galaxy S8', width: '360px', height: '740px' },
    { title: 'Pixel 5', width: '393px', height: '851px' },
    { title: 'Responsive', width: '375px', height: '667px' }
  ];

  languages: any = new Array<any>();
  displaySizeSelected = this.displaySize[0];
  widthReponsive: number = 375; // data input width reponsive
  heightReponsive: number = 667; // data input height reponsive
  isReponsive: boolean; //true if is reponsive
  listOperators: any = new Array<any>();
  isCoupon: boolean = false;
  isEnableOn: boolean;

  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPEG];

  placeholderSelectDate: string; // Placeholder for input type date
  availableDeadlineAt: Date; // Usage Start Date
  labelAddImage: string; //label Add Image
  acceptFileTypes: string = '.jpg, .png, .jpeg';
  formatDate: string;
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component

  @Output()
  public colorHex = new EventEmitter<string>();
  @ViewChild('fileInput1') fileInput1: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  @ViewChild('fileInput3') fileInput3: ElementRef;
  @ViewChild('fileInput4') fileInput4: ElementRef;
  @ViewChild('listDisplay', { static: false }) listDisplay: ElementRef;
  @ViewChild('rowInfo', { static: false }) rowInfo: ElementRef;
  @ViewChild('rowInfoUpdate', { static: false }) rowInfoUpdate: ElementRef;

  headerColumns: any[] = [
    { headerName: 'ticket-editor.ticket-tab.ticketID', property: 'ticketId' },
    { headerName: 'ticket-editor.ticket-tab.ticketName', property: 'ticketName' },
    { headerName: 'ticket-editor.ticket-tab.type', property: 'ticketType' },
    { headerName: 'ticket-editor.ticket-tab.isReserve', property: 'isReserve' },
    { headerName: 'ticket-editor.ticket-tab.operation', property: 'operatorName' },
    { headerName: 'ticket-editor.ticket-tab.applicationName', property: 'appName' },
    { headerName: 'ticket-editor.ticket-tab.validity-period', property: 'validityPeriod' },
    { headerName: 'ticket-editor.ticket-tab.schedule-update', property: 'updateScheduleAt' },
    { headerName: 'ticket-editor.ticket-tab.status', property: 'inUse' }
  ];
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  isShowPopUpSortFilter: boolean;
  columnSortFiltering: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOriginal: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];
  sortFilterObject: SortFilterObject;
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  isOpenEnableOn: boolean;
  isOpenDisableOn: boolean;
  Helper = Helper;

  constructor(
    private translateService: TranslateService,
    private menuActionService: MenuActionService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private dataService: DataService,
    private ticketService: TicketEditorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
    this.subscriptions.push(
      // Action add
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.addNewTicket();
        }
      }),
      // Action add schedule
      this.menuActionService.actionAddSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.addNewTicketUpdate();
        }
      }),
      // Action duplicate
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.duplicateTicket();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.editTicket();
        }
      }),
      // Action edit schedule
      this.menuActionService.actionEditSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.editTicketUpdate();
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.deleteTicket();
        }
      }),
      // Action Delete Schedule
      this.menuActionService.actionDeleteSchedule.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.deleteScheduleUpdate();
        }
      }),
      //confirmSaleTicket
      this.menuActionService.actionConfirmSaleTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.confirmSaleTicket();
        }
      }),
      //Sort and filter
      this.menuActionService.actionSortFilterTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.sortAndFilter();
        }
      }),
      // Action Delete Schedule
      this.menuActionService.actionTranslateTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.TICKET_ENUM) {
          this.translateTicket();
        }
      }),
      // Returns the currently displayed language
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        this.languageSelectedPreview = _.cloneDeep(this.languageSelected);
        if (this.titleAdd) {
          this.titleAdd = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add');
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add');
        } else if (this.titleAddSchedule) {
          this.titleAddSchedule = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
        } else if (this.titleEdit) {
          this.titleEdit = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit');
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit');
        } else if (this.titleEditSchedule) {
          this.titleEditSchedule = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
        } else if (this.titleDuplicate) {
          this.titleDuplicate = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate');
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate');
        }
        this.changeTitleFormData();
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey)
        };
        this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.add-image');
        if (this.languages?.length) {
          this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
        }
        this.ticketDisplays = this.convertListTicket(_.cloneDeep(this.tickets));
        this.dataFilterOrderConvert = this.convertListTicket(_.cloneDeep(this.tickets));
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        //lấy LanguageCode từ app và sắp xếp
        const languagesCodes = this.languages.map(e => e.translation_language_code);
        const newlanguagesCodes = Helper.getLanguagesCode(languagesCodes, this.commonService);
        this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
        //Sắp xếp LanguageCode (để languageSelected lên đầu)
        this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
        if (this.languages?.length) {
          this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
        }
      }
    });
    this.isPreview1 = true;
    this.headerColumns.forEach(e => {
      e.isFilterBy = '';
      e.isSortBy = '';
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getAllApplication();
    await this.getListTicket();
    this.ticketService.getCompanies(this.informationAccount).subscribe(data => {
      this.listOperators = data;
      this.languageKey = this.commonObject?.setting?.language;
      this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
      this.languageSelectedPreview = _.cloneDeep(this.languageSelected);
      this.config = {
        showWeekNumbers: false,
        format: 'YYYY-MM-DD',
        firstDayOfWeek: 'mo',
        unSelectOnClick: false,
        locale: Helper.getLocale(this.languageKey)
      };
    });
    this.timezone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '')
      .replace('GMT', '');
    if (!this.timezone) {
      this.timezone = 'Z';
    }
    this.formatDate = 'yyyy-MM-dd';
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketService.findAllApplication(this.informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  ngAfterViewInit(): void {
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  ngOnDestroy(): void {
    this.cancelTicketDetail(true);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * definesTheFirstLine
   */
  definesTheFirstLine(object: any): string {
    if (!object) {
      return;
    }
    if (this.languageKey == 'jp' && this.languageTranslation?.includes('ja') && Object.keys(object).includes('ja')) {
      return 'ja';
    } else if (this.languageKey == 'en' && this.languageTranslation?.includes('en') && Object.keys(object).includes('en')) {
      return 'en';
    } else {
      return Object.keys(object)[0];
    }
  }

  /**
   * getListTicket
   */
  getListTicket(): Promise<void> {
    return new Promise((resolve, reject) => {
      const idSelected = this.ticketSelected ? this.ticketSelected.ticketId : -1;
      this.ticketService.getTickets(this.informationAccount).subscribe(
        data => {
          this.tickets = Helper.convertTicket(data);
          this.ticketDisplays = this.convertListTicket(_.cloneDeep(this.tickets));
          this.dataFilterOrderConvert = this.convertListTicket(_.cloneDeep(this.tickets));
          this.changeDetectorRef.detectChanges();
          if (this.ticketDisplays && this.ticketDisplays.length > 0) {
            if (idSelected != -1 && this.tickets.findIndex(e => e.ticketId == idSelected) != -1) {
              this.selectTicket(this.ticketDisplays[this.ticketDisplays.findIndex(e => e.ticketId == idSelected)], null);
            } else {
              this.selectTicket(this.ticketDisplays[0], null);
            }
          }
          resolve();
        },
        error => reject(error)
      );
    });
  }

  /**
   * Set data for preview application
   *
   * @param widthReponsive
   * @param heightReponsive
   */
  setDisplaySizePreview(widthReponsive: number, heightReponsive: number): void {
    const data = {
      width: widthReponsive,
      height: heightReponsive
    };
    if (this.previewComponent !== undefined) {
      this.previewComponent.setDisplaySizePreview(data);
    }
  }

  /**
   * change Display Size
   */
  changeDisplaySize(index: number) {
    this.displaySizeSelected = this.displaySize[index];
    this.isReponsive = this.displaySizeSelected.title == 'Responsive';
    this.widthReponsive = +this.displaySizeSelected.width.slice(0, -2);
    this.heightReponsive = +this.displaySizeSelected.height.slice(0, -2);
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * onWidth
   */
  onWidth(): void {
    if (this.widthReponsive < Constant.MIN_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MIN_WIDTH_RESPONSIVE;
    } else if (this.widthReponsive > Constant.MAX_WIDTH_RESPONSIVE) {
      this.widthReponsive = Constant.MAX_WIDTH_RESPONSIVE;
    }
    this.displaySize[7].width = this.widthReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * onHeight
   */
  onHeight(): void {
    if (this.heightReponsive < Constant.MIN_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MIN_HEIGHT_RESPONSIVE;
    } else if (this.heightReponsive > Constant.MAX_HEIGHT_RESPONSIVE) {
      this.heightReponsive = Constant.MAX_HEIGHT_RESPONSIVE;
    }
    this.displaySize[7].height = this.heightReponsive.toString() + 'px';
    this.setDisplaySizePreview(this.widthReponsive, this.heightReponsive);
  }

  /**
   * select Ticket
   * @param ticket
   * @param event
   * @returns
   */
  selectTicket(ticket: Ticket, event: any, isSelecting?: boolean): void {
    // check click button checkbox
    if (!ticket || !ticket.ticketId) {
      return;
    }
    if (isSelecting) {
      this.isChooseDetailUpdate = false;
    }
    if (ticket.inUse == '未使用') {
      this.dataService.sendData([Constant.IS_USE, false]);
    } else if (ticket.inUse == '使用中') {
      this.dataService.sendData([Constant.IS_USE, true]);
    }
    this.ticketService.getSalesTickets(this.informationAccount, ticket.ticketId).subscribe(data => {
      this.saleTickets = data;
    });
    this.date = undefined;
    this.time = '00:00';
    this.image1 = undefined;
    this.image2 = undefined;
    forkJoin({
      ticketDetail: this.ticketService.getTicket(this.informationAccount, +ticket.ticketId),
      ticketDetailUpdate: this.ticketService.getTicketUpdate(this.informationAccount, +ticket.ticketId)
    }).subscribe(
      data => {
        this.languages = [];
        let indexApp = this.listApp?.findIndex(e => e.appId == ticket.appId);
        if (indexApp !== -1) {
          this.languages = Constant.LANGUAGES_SETTING.filter(e =>
            this.listApp[indexApp].supportedLanguage?.includes(e.translation_language_code)
          );
          //lấy LanguageCode từ app và sắp xếp
          const languagesCodes = this.languages.map(e => e.translation_language_code);
          const newlanguagesCodes = Helper.getLanguagesCode(languagesCodes, this.commonService);
          this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
          //Sắp xếp LanguageCode (để languageSelected lên đầu)
          this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
          this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
        }
        const keyLanguages = this.languages.map(e => e.translation_language_code);

        ticket.ticketDetail = data.ticketDetail;
        ticket.ticketDetailUpdate = data.ticketDetailUpdate;
        ticket.ticketDetail.ticketName = this.convertEmptyObjectsByLanguage(ticket.ticketDetail.ticketName, keyLanguages);
        ticket.ticketDetail.ticketNameShort = this.convertEmptyObjectsByLanguage(ticket.ticketDetail.ticketNameShort, keyLanguages);
        ticket.ticketDetail.subtitle = this.convertEmptyObjectsByLanguage(ticket.ticketDetail.subtitle, keyLanguages);
        ticket.ticketDetail.note = this.convertEmptyObjectsByLanguage(ticket.ticketDetail.note, keyLanguages);
        ticket.ticketDetail.activateMessage = this.convertEmptyObjectsByLanguage(ticket.ticketDetail.activateMessage, keyLanguages);
        ticket.ticketDetailUpdate.ticketName = this.convertEmptyObjectsByLanguage(ticket.ticketDetailUpdate.ticketName, keyLanguages);
        ticket.ticketDetailUpdate.ticketNameShort = this.convertEmptyObjectsByLanguage(
          ticket.ticketDetailUpdate.ticketNameShort,
          keyLanguages
        );
        ticket.ticketDetailUpdate.subtitle = this.convertEmptyObjectsByLanguage(ticket.ticketDetailUpdate.subtitle, keyLanguages);
        ticket.ticketDetailUpdate.note = this.convertEmptyObjectsByLanguage(ticket.ticketDetailUpdate.note, keyLanguages);
        ticket.ticketDetailUpdate.activateMessage = this.convertEmptyObjectsByLanguage(
          ticket.ticketDetailUpdate.activateMessage,
          keyLanguages
        );
        if (!ticket.ticketDetail.enableOn) {
          ticket.ticketDetail.enableOn = [];
        }
        if (!ticket.ticketDetail.disableOn) {
          ticket.ticketDetail.disableOn = [];
        }
        if (!ticket.ticketDetail.enableActivateWeekdays) {
          ticket.ticketDetail.enableActivateWeekdays = [];
        }
        ticket.ticketDetail.urlImage1 = _.cloneDeep(ticket.ticketDetail.image1);
        ticket.ticketDetail.urlImage2 = _.cloneDeep(ticket.ticketDetail.image2);
        if (ticket.ticketDetailUpdate.updateScheduleAt) {
          const tmp = ticket.ticketDetailUpdate.updateScheduleAt.split('T');
          const time = tmp[1].split(':');
          this.date = tmp[0];
          this.time = `${time[0]}:${time[1]}`;
        }
        if (Object.keys(ticket.ticketDetailUpdate).length) {
          ticket.ticketDetailUpdate.urlImage1 = _.cloneDeep(ticket.ticketDetailUpdate.image1);
          ticket.ticketDetailUpdate.urlImage2 = _.cloneDeep(ticket.ticketDetailUpdate.image2);
          if (!ticket.ticketDetailUpdate.enableOn) {
            ticket.ticketDetailUpdate.enableOn = [];
          }
          if (!ticket.ticketDetailUpdate.disableOn) {
            ticket.ticketDetailUpdate.disableOn = [];
          }
          if (!ticket.ticketDetailUpdate.enableActivateWeekdays) {
            ticket.ticketDetailUpdate.enableActivateWeekdays = [];
          }
        }
        this.ticketSelected = ticket;
        // Call api de lay main-color
        if (ticket.appId) {
          this.ticketService.getApplicationDesigns(ticket.appId, this.informationAccount).subscribe(data => {
            const index = data.findIndex(e => e.applied == true);
            if (index != -1) {
              this.mainColor = data[index].mainColor;
              this.changeDetectorRef.detectChanges();
            }
          });
        }
        this.isCoupon = this.ticketSelected.ticketType == '綴り券' ? true : false;
        this.ticketSelectedClone = _.cloneDeep(this.ticketSelected);
        if (this.ticketSelected.ticketDetailUpdate?.updateScheduleAt) {
          this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, true]);
        } else {
          this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
        }
        this.ticketId = ticket.ticketId;
        this.changeDetectorRef.detectChanges();
        if (this.isChooseDetailUpdate) {
          this.rowInfoUpdate.nativeElement.scrollTop = 0;
        } else {
          this.rowInfo.nativeElement.scrollTop = 0;
        }
      },
      error => Helper.getErrorTicket(error, this.translateService, this.dialogService)
    );
  }

  /**
   * convertEmptyObjectsByLanguage
   * @param object
   * @param languages
   * @returns
   */
  convertEmptyObjectsByLanguage(object: any, languages: string[]): any {
    if (!object || (object && !Object.keys(object).length)) {
      let temp = {};
      languages.forEach(element => {
        temp[element] = '';
      });
      return temp;
    }
    return object;
  }

  /**
   * cancel Ticket Detail
   */
  cancelTicketDetail(isOnDestroy?: any): void {
    if (!this.ticketDisplays || (this.ticketDisplays && !this.ticketDisplays.length)) {
      this.ticketSelected = undefined;
    } else {
      this.isEditTicketDetailUpdate = false;
      this.isEditTicketDetail = false;
      let index;
      if (!this.ticketSelected.ticketId) {
        index = this.ticketDisplays.findIndex(e => e.ticketId == this.ticketId);
      } else {
        index = this.ticketDisplays.findIndex(e => e.ticketId == this.ticketSelected.ticketId);
      }
      if (!isOnDestroy) {
        this.selectTicket(index != -1 ? this.ticketDisplays[index] : this.ticketDisplays[0], null);
      }
    }
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
    this.focusOn = '';
    this.titleFormData = '';
    delete this.titleEdit;
    delete this.titleEditSchedule;
    delete this.titleAdd;
    delete this.titleAddSchedule;
    delete this.titleDuplicate;
  }

  /**
   * save TicketDetail
   */
  saveTicketDetail(): void {
    this.focusOn = '';
    if (this.validateBeforeSave()) {
      return;
    }
    this.ticketSelectedClone = _.cloneDeep(this.ticketSelected);
    const detail = this.isChooseDetailUpdate ? this.ticketSelected.ticketDetailUpdate : this.ticketSelected.ticketDetail;
    let ticketName = {};
    let ticketNameShort = {};
    let subtitle = {};
    let note = {};
    let activateMessage = {};
    this.languages.forEach(e => {
      activateMessage[e.translation_language_code] = detail.activateMessage[e.translation_language_code];
      ticketName[e.translation_language_code] = detail.ticketName[e.translation_language_code];
      ticketNameShort[e.translation_language_code] = detail.ticketNameShort[e.translation_language_code];
      subtitle[e.translation_language_code] = detail.subtitle[e.translation_language_code];
      note[e.translation_language_code] = detail.note[e.translation_language_code];
    });
    let payload = {
      ticketType: detail.ticketType,
      ticketName: ticketName,
      ticketNameShort: ticketNameShort,
      operatorId: +detail.operatorId,
      appId: detail.appId,
      subtitle: subtitle,
      note: note,
      activateMessage: detail.isReserve ? undefined : activateMessage,
      inUseOperation: +detail.inUseOperation ?? undefined,
      usingPeriod: detail.inUseOperation != 2 ? +detail.usingPeriod ?? undefined : undefined,
      usingPeriodType: detail.inUseOperation != 2 ? detail.usingPeriodType ?? null : undefined,
      enableActivateWeekdays: detail.isReserve ? undefined : detail.enableActivateWeekdays,
      availableActiveStartTime: detail.isReserve
        ? undefined
        : detail.availableActiveStartTime
        ? detail.availableActiveStartTime
        : undefined,
      availableActiveEndTime: detail.isReserve ? undefined : detail.availableActiveEndTime ? detail.availableActiveEndTime : undefined,
      availableShowEndTime: detail.isReserve ? undefined : detail.availableShowEndTime ? detail.availableShowEndTime : undefined,
      isReserve: detail.isReserve ? true : false,
      currencyUnit: detail.currencyUnit,
      isInUseAdult: detail.isInUseAdult ? true : false,
      isInUseCustom1: detail.isInUseCustom1 ? true : false,
      isInUseCustom2: detail.isInUseCustom2 ? true : false,
      isInUseCustom3: detail.isInUseCustom3 ? true : false,
      priceLabelCustom1: detail.isInUseCustom1 ? detail.priceLabelCustom1 : undefined,
      priceLabelCustom2: detail.isInUseCustom2 ? detail.priceLabelCustom2 : undefined,
      priceLabelCustom3: detail.isInUseCustom3 ? detail.priceLabelCustom3 : undefined,
      referencePriceAdult: detail.isReserve ? undefined : detail.isInUseAdult ? detail.referencePriceAdult : undefined,
      referencePriceCustom1: detail.isReserve ? undefined : detail.isInUseCustom1 ? detail.referencePriceCustom1 : undefined,
      referencePriceCustom2: detail.isReserve ? undefined : detail.isInUseCustom2 ? detail.referencePriceCustom2 : undefined,
      referencePriceCustom3: detail.isReserve ? undefined : detail.isInUseCustom3 ? detail.referencePriceCustom3 : undefined,
      reservationAvailablePeriod: detail.isReserve ? +detail.reservationAvailablePeriod ?? undefined : undefined,
      reservationAvailablePeriodUnit: detail.isReserve
        ? detail.reservationAvailablePeriodUnit
          ? detail.reservationAvailablePeriodUnit
          : undefined
        : undefined,
      reservationDeadline: detail.isReserve ? +detail.reservationDeadline ?? undefined : undefined,
      reservationDeadlineUnit: detail.isReserve ? (detail.reservationDeadlineUnit ? detail.reservationDeadlineUnit : undefined) : undefined,
      reservationDisplayStart: detail.isReserve ? +detail.reservationDisplayStart ?? undefined : undefined,
      reservationDisplayStartUnit: detail.isReserve
        ? detail.reservationDisplayStartUnit
          ? detail.reservationDisplayStartUnit
          : undefined
        : undefined,
      enableOn: detail.isReserve ? undefined : detail.enableOn,
      disableOn: detail.isReserve ? undefined : detail.disableOn,
      colorCircleUpper: detail.colorCircleUpper?.toLocaleUpperCase(),
      colorCircleLower: detail.colorCircleLower?.toLocaleUpperCase(),
      colorCircleRotation: detail.colorCircleRotation?.toLocaleUpperCase(),
      image1Path: detail.image1Path ?? null,
      image2Path: detail.image2Path ?? null,
      colorFontTicket1Top: detail.colorFontTicket1Top?.toLocaleUpperCase(),
      colorFontTicket1Center: detail.colorFontTicket1Center?.toLocaleUpperCase(),
      colorFontTicket1Bottom: detail.colorFontTicket1Bottom?.toLocaleUpperCase(),
      colorFontTicket2Top: detail.colorFontTicket2Top?.toLocaleUpperCase(),
      colorFontTicket2Center: detail.colorFontTicket2Center?.toLocaleUpperCase(),
      colorFontTicket2Bottom: detail.colorFontTicket2Bottom?.toLocaleUpperCase()
    };
    if (payload.ticketType == 'coupon' && payload.inUseOperation == 2) {
      payload[`couponCount`] = +detail.couponCount;
      payload[`couponGrantingType`] = detail.couponGrantingType ?? null;
      this.image1 = undefined;
      this.image2 = undefined;
      payload.colorCircleUpper = null;
      payload.colorCircleLower = null;
      payload.colorCircleRotation = null;
      payload.colorFontTicket1Top = null;
      payload.colorFontTicket1Center = null;
      payload.colorFontTicket1Bottom = null;
      payload.colorFontTicket2Top = null;
      payload.colorFontTicket2Center = null;
      payload.colorFontTicket2Bottom = null;
    }
    if (
      this.titleFormData.includes(this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule')) ||
      this.titleFormData.includes(this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule'))
    ) {
      delete payload.operatorId;
      delete payload.inUseOperation;
      delete payload.appId;
      delete payload.enableOn;
      delete payload.disableOn;
      delete payload.ticketType;
    }
    if (this.image1 || this.image2) {
      let payload1 = {
        image: this.image1,
        uploadImageType: this.isChooseDetailUpdate ? 'ticket_updates' : 'tickets'
      };
      let payload2 = {
        image: this.image2,
        uploadImageType: this.isChooseDetailUpdate ? 'ticket_updates' : 'tickets'
      };
      const image1$ = this.image1 ? this.ticketService.postImage(this.informationAccount, payload1) : of({});
      const image2$ = this.image2 ? this.ticketService.postImage(this.informationAccount, payload2) : of({});
      forkJoin([image1$, image2$])
        .pipe(
          map(([result1, result2]) => {
            return { result1, result2 };
          })
        )
        .subscribe(
          result => {
            payload.image1Path = result?.result1?.filePath ? result?.result1?.filePath : detail.image1Path ?? null;
            payload.image2Path = result?.result2?.filePath ? result?.result2?.filePath : detail.image2Path ?? null;
            this.callAPISaveTicket(payload);
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
          }
        );
    } else {
      this.callAPISaveTicket(payload);
    }
  }

  /**
   * callAPISaveTicket
   * @param payload
   */
  callAPISaveTicket(payload) {
    switch (this.titleFormData) {
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add'):
        //call api add
        payload.ticketName = this.filterPropertyByLanguages(payload.ticketName, this.languages);
        payload.ticketNameShort = this.convertProperty(payload.ticketNameShort);
        payload.ticketNameShort = this.filterPropertyByLanguages(payload.ticketNameShort, this.languages);
        payload.subtitle = this.convertProperty(payload.subtitle);
        payload.subtitle = this.filterPropertyByLanguages(payload.subtitle, this.languages);
        payload.note = this.convertProperty(payload.note);
        payload.note = this.filterPropertyByLanguages(payload.note, this.languages);
        payload.activateMessage = this.filterPropertyByLanguages(payload.activateMessage, this.languages);
        this.ticketService.postTicket(this.informationAccount, payload).subscribe(
          data => {
            this.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTickets(this.informationAccount).subscribe(
              listTicket => {
                this.tickets = Helper.convertTicket(listTicket);
                this.ticketDisplays = this.convertListTicket(_.cloneDeep(this.tickets));
                this.dataFilterOrderConvert = this.convertListTicket(_.cloneDeep(this.tickets));
                if (this.isSortFilter) {
                  this.filterData(data.ticketId);
                } else {
                  const index = this.ticketDisplays.findIndex(e => e.ticketId == data.ticketId);
                  this.selectTicket(this.ticketDisplays[index], null);
                }
                this.changeDetectorRef.detectChanges();
                this.listDisplay.nativeElement.scrollTop = this.listDisplay.nativeElement.scrollHeight;
                this.titleFormData = '';
                delete this.titleAdd;
              },
              error => {
                Helper.getErrorTicket(error, this.translateService, this.dialogService);
                this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
                return;
              }
            );
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            return;
          }
        );
        this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate'):
        //call api add schedule
        payload.ticketName = this.filterPropertyByLanguages(payload.ticketName, this.languages);
        payload.ticketNameShort = this.convertProperty(payload.ticketNameShort);
        payload.ticketNameShort = this.filterPropertyByLanguages(payload.ticketNameShort, this.languages);
        payload.subtitle = this.convertProperty(payload.subtitle);
        payload.subtitle = this.filterPropertyByLanguages(payload.subtitle, this.languages);
        payload.note = this.convertProperty(payload.note);
        payload.note = this.filterPropertyByLanguages(payload.note, this.languages);
        payload.activateMessage = this.filterPropertyByLanguages(payload.activateMessage, this.languages);
        this.ticketService.postTicket(this.informationAccount, payload).subscribe(
          data => {
            this.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTickets(this.informationAccount).subscribe(
              listTicket => {
                this.tickets = Helper.convertTicket(listTicket);
                this.ticketDisplays = this.convertListTicket(_.cloneDeep(this.tickets));
                this.dataFilterOrderConvert = this.convertListTicket(_.cloneDeep(this.tickets));
                const index = this.ticketDisplays.findIndex(e => e.ticketId == data.ticketId);
                if (this.isSortFilter) {
                  this.filterData(data.ticketId);
                } else {
                  this.selectTicket(this.ticketDisplays[index], null);
                }
                this.changeDetectorRef.detectChanges();
                this.listDisplay.nativeElement.scrollTop = this.listDisplay.nativeElement.scrollHeight;
                this.titleFormData = '';
                delete this.titleDuplicate;
              },
              error => Helper.getErrorTicket(error, this.translateService, this.dialogService)
            );
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
          }
        );
        this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule'):
        payload.ticketNameShort = this.convertProperty(payload.ticketNameShort);
        payload.subtitle = this.convertProperty(payload.subtitle);
        payload.note = this.convertProperty(payload.note);
        payload.enableActivateWeekdays =
          payload.enableActivateWeekdays && payload.enableActivateWeekdays.length ? payload.enableActivateWeekdays : null;
        const dateSchedule = new Date(this.date);
        const dateInfo = {
          year: dateSchedule.getFullYear(),
          month: dateSchedule.getMonth(),
          day: dateSchedule.getDate(),
          hour: parseInt(this.time.split(':')[0]),
          minute: parseInt(this.time.split(':')[1]),
          second: 0
        };
        const momentObject = moment(dateInfo).format('YYYY-MM-DDTHH:mm:ss');
        this.ticketSelected.ticketDetailUpdate.updateScheduleAt = momentObject + this.timezone;
        payload[`updateScheduleAt`] = momentObject + this.timezone;
        const payloadAddSchedule = {
          ticketId: +this.ticketSelected.ticketId ?? null,
          updateScheduleAt: payload[`updateScheduleAt`],
          ticketName: payload.ticketName ?? null,
          ticketNameShort: payload.ticketNameShort,
          subtitle: payload.subtitle,
          note: payload.note,
          activateMessage: payload.activateMessage ?? null,
          isInUseAdult: payload.isInUseAdult,
          isInUseCustom1: payload.isInUseCustom1,
          isInUseCustom2: payload.isInUseCustom2,
          isInUseCustom3: payload.isInUseCustom3,
          priceLabelCustom1: payload.priceLabelCustom1 ?? null,
          priceLabelCustom2: payload.priceLabelCustom2 ?? null,
          priceLabelCustom3: payload.priceLabelCustom3 ?? null,
          referencePriceAdult: +payload.referencePriceAdult ?? null,
          referencePriceCustom1: +payload.referencePriceCustom1 ?? null,
          referencePriceCustom2: +payload.referencePriceCustom2 ?? null,
          referencePriceCustom3: +payload.referencePriceCustom3 ?? null,
          currencyUnit: payload.currencyUnit ?? null,
          usingPeriod: +payload.usingPeriod ?? null,
          usingPeriodType: payload.usingPeriodType ?? null,
          enableActivateWeekdays: payload.enableActivateWeekdays,
          availableActiveStartTime: payload.availableActiveStartTime ?? null,
          availableActiveEndTime: payload.availableActiveEndTime ?? null,
          availableShowEndTime: payload.availableShowEndTime ?? null,
          colorCircleUpper: payload.colorCircleUpper ?? null,
          colorCircleLower: payload.colorCircleLower ?? null,
          colorCircleRotation: payload.colorCircleRotation ?? null,
          image1Path: payload.image1Path ?? null,
          image2Path: payload.image2Path ?? null,
          colorFontTicket1Top: payload.colorFontTicket1Top ?? null,
          colorFontTicket1Center: payload.colorFontTicket1Center ?? null,
          colorFontTicket1Bottom: payload.colorFontTicket1Bottom ?? null,
          colorFontTicket2Top: payload.colorFontTicket2Top ?? null,
          colorFontTicket2Center: payload.colorFontTicket2Center ?? null,
          colorFontTicket2Bottom: payload.colorFontTicket2Bottom ?? null,
          reservationAvailablePeriod: +payload.reservationAvailablePeriod ?? null,
          reservationAvailablePeriodUnit: payload.reservationAvailablePeriodUnit ?? null,
          reservationDeadline: +payload.reservationDeadline ?? null,
          reservationDeadlineUnit: payload.reservationDeadlineUnit ?? null,
          reservationDisplayStart: +payload.reservationDisplayStart ?? null,
          reservationDisplayStartUnit: payload.reservationDisplayStartUnit ?? null
        };
        this.ticketService.postTicketUpdate(this.informationAccount, payloadAddSchedule).subscribe(
          data => {
            this.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTicketUpdate(this.informationAccount, data.ticketId).subscribe(ticketDetailUpdate => {
              const keyLanguages = this.languages.map(e => e.translation_language_code);
              ticketDetailUpdate.ticketName = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.ticketName, keyLanguages);
              ticketDetailUpdate.ticketNameShort = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.ticketNameShort, keyLanguages);
              ticketDetailUpdate.subtitle = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.subtitle, keyLanguages);
              ticketDetailUpdate.note = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.note, keyLanguages);
              ticketDetailUpdate.activateMessage = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.activateMessage, keyLanguages);
              this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
              this.ticketSelected.updateScheduleAt = ticketDetailUpdate?.updateScheduleAt;
              const indexSetTicket = this.tickets.findIndex(e => e.ticketId == data.ticketId);
              this.tickets[indexSetTicket].updateScheduleAt = ticketDetailUpdate?.updateScheduleAt;
              this.ticketSelected.ticketDetailUpdate.urlImage1 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image1);
              this.ticketSelected.ticketDetailUpdate.urlImage2 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image2);
              this.rowInfoUpdate.nativeElement.scrollTop = 0;
              this.titleFormData = '';
              delete this.titleAddSchedule;
            });
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            return;
          }
        );
        this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, true]);
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit'):
        //call api edit
        payload.ticketNameShort = this.convertProperty(payload.ticketNameShort);
        payload.subtitle = this.convertProperty(payload.subtitle);
        payload.note = this.convertProperty(payload.note);
        const payloadEdit = {
          ticketId: +this.ticketSelected.ticketId ?? null,
          ticketName: payload.ticketName ?? null,
          ticketNameShort: payload.ticketNameShort,
          subtitle: payload.subtitle,
          note: payload.note,
          activateMessage: payload.activateMessage ?? null,
          enableOn: payload.enableOn ?? null,
          disableOn: payload.disableOn ?? null,
          isInUseAdult: payload.isInUseAdult,
          isInUseCustom1: payload.isInUseCustom1,
          isInUseCustom2: payload.isInUseCustom2,
          isInUseCustom3: payload.isInUseCustom3,
          priceLabelCustom1: payload.priceLabelCustom1 ?? null,
          priceLabelCustom2: payload.priceLabelCustom2 ?? null,
          priceLabelCustom3: payload.priceLabelCustom3 ?? null,
          referencePriceAdult: +payload.referencePriceAdult ?? null,
          referencePriceCustom1: +payload.referencePriceCustom1 ?? null,
          referencePriceCustom2: +payload.referencePriceCustom2 ?? null,
          referencePriceCustom3: +payload.referencePriceCustom3 ?? null,
          currencyUnit: payload.currencyUnit ?? null,
          reservationAvailablePeriod: +payload.reservationAvailablePeriod ?? null,
          reservationAvailablePeriodUnit: payload.reservationAvailablePeriodUnit ?? null,
          reservationDeadline: +payload.reservationDeadline ?? null,
          reservationDeadlineUnit: payload.reservationDeadlineUnit ?? null,
          reservationDisplayStart: +payload.reservationDisplayStart ?? null,
          reservationDisplayStartUnit: payload.reservationDisplayStartUnit ?? null
        };
        this.ticketService.putTicket(this.informationAccount, payloadEdit).subscribe(
          data => {
            const ticketId = data.ticketId;
            this.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTickets(this.informationAccount).subscribe(listTicket => {
              this.tickets = Helper.convertTicket(listTicket);
              this.ticketDisplays = this.convertListTicket(_.cloneDeep(this.tickets));
              this.dataFilterOrderConvert = this.convertListTicket(_.cloneDeep(this.tickets));
              this.rowInfo.nativeElement.scrollTop = 0;
              if (this.isSortFilter) {
                this.filterData(ticketId);
                this.titleFormData = '';
                delete this.titleEdit;
              } else {
                const index = this.ticketDisplays.findIndex(e => e.ticketId == data.ticketId);
                this.selectTicket(this.ticketDisplays[index], null);
              }
            });
            this.titleFormData = '';
            delete this.titleEdit;
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            return;
          }
        );
        break;
      case this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule'):
        //call api add/edit-schedule
        payload.enableActivateWeekdays =
          payload.enableActivateWeekdays && payload.enableActivateWeekdays.length ? payload.enableActivateWeekdays : null;
        const selectedTime = new Date(this.date);
        const dateInfoEditor = {
          year: selectedTime.getFullYear(),
          month: selectedTime.getMonth(),
          day: selectedTime.getDate(),
          hour: parseInt(this.time.split(':')[0]),
          minute: parseInt(this.time.split(':')[1]),
          second: 0
        };
        const momentObjectEditor = moment(dateInfoEditor).format('YYYY-MM-DDTHH:mm:ss');
        this.ticketSelected.ticketDetailUpdate.updateScheduleAt = momentObjectEditor + this.timezone;
        payload[`updateScheduleAt`] = momentObjectEditor + this.timezone;
        payload.ticketNameShort = this.convertProperty(payload.ticketNameShort);
        payload.subtitle = this.convertProperty(payload.subtitle);
        payload.note = this.convertProperty(payload.note);
        const payloadEditSchedule = {
          ticketId: +this.ticketSelected.ticketId ?? null,
          updateScheduleAt: payload[`updateScheduleAt`],
          ticketName: payload.ticketName ?? null,
          ticketNameShort: payload.ticketNameShort,
          subtitle: payload.subtitle,
          note: payload.note,
          activateMessage: payload.activateMessage ?? null,
          isInUseAdult: payload.isInUseAdult,
          isInUseCustom1: payload.isInUseCustom1,
          isInUseCustom2: payload.isInUseCustom2,
          isInUseCustom3: payload.isInUseCustom3,
          priceLabelCustom1: payload.priceLabelCustom1 ?? null,
          priceLabelCustom2: payload.priceLabelCustom2 ?? null,
          priceLabelCustom3: payload.priceLabelCustom3 ?? null,
          referencePriceAdult: +payload.referencePriceAdult ?? null,
          referencePriceCustom1: +payload.referencePriceCustom1 ?? null,
          referencePriceCustom2: +payload.referencePriceCustom2 ?? null,
          referencePriceCustom3: +payload.referencePriceCustom3 ?? null,
          currencyUnit: payload.currencyUnit ?? null,
          usingPeriod: +payload.usingPeriod ?? null,
          usingPeriodType: payload.usingPeriodType ?? null,
          enableActivateWeekdays: payload.enableActivateWeekdays,
          availableActiveStartTime: payload.availableActiveStartTime ?? null,
          availableActiveEndTime: payload.availableActiveEndTime ?? null,
          availableShowEndTime: payload.availableShowEndTime ?? null,
          colorCircleUpper: payload.colorCircleUpper ?? null,
          colorCircleLower: payload.colorCircleLower ?? null,
          colorCircleRotation: payload.colorCircleRotation ?? null,
          image1Path: payload.image1Path ?? null,
          image2Path: payload.image2Path ?? null,
          colorFontTicket1Top: payload.colorFontTicket1Top ?? null,
          colorFontTicket1Center: payload.colorFontTicket1Center ?? null,
          colorFontTicket1Bottom: payload.colorFontTicket1Bottom ?? null,
          colorFontTicket2Top: payload.colorFontTicket2Top ?? null,
          colorFontTicket2Center: payload.colorFontTicket2Center ?? null,
          colorFontTicket2Bottom: payload.colorFontTicket2Bottom ?? null,
          reservationAvailablePeriod: +payload.reservationAvailablePeriod ?? null,
          reservationAvailablePeriodUnit: payload.reservationAvailablePeriodUnit ?? null,
          reservationDeadline: +payload.reservationDeadline ?? null,
          reservationDeadlineUnit: payload.reservationDeadlineUnit ?? null,
          reservationDisplayStart: +payload.reservationDisplayStart ?? null,
          reservationDisplayStartUnit: payload.reservationDisplayStartUnit ?? null
        };
        this.ticketService.postTicketUpdate(this.informationAccount, payloadEditSchedule).subscribe(
          data => {
            this.isChooseDetailUpdate ? (this.isEditTicketDetailUpdate = false) : (this.isEditTicketDetail = false);
            this.ticketService.getTicketUpdate(this.informationAccount, data.ticketId).subscribe(ticketDetailUpdate => {
              const keyLanguages = this.languages.map(e => e.translation_language_code);
              ticketDetailUpdate.ticketName = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.ticketName, keyLanguages);
              ticketDetailUpdate.ticketNameShort = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.ticketNameShort, keyLanguages);
              ticketDetailUpdate.subtitle = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.subtitle, keyLanguages);
              ticketDetailUpdate.note = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.note, keyLanguages);
              ticketDetailUpdate.activateMessage = this.convertEmptyObjectsByLanguage(ticketDetailUpdate.activateMessage, keyLanguages);
              this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
              this.ticketSelected.updateScheduleAt = ticketDetailUpdate?.updateScheduleAt;
              const indexSetTicket = this.tickets.findIndex(e => e.ticketId == data.ticketId);
              this.tickets[indexSetTicket].updateScheduleAt = ticketDetailUpdate?.updateScheduleAt;
              this.ticketSelected.ticketDetailUpdate.urlImage1 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image1);
              this.ticketSelected.ticketDetailUpdate.urlImage2 = _.cloneDeep(this.ticketSelected.ticketDetailUpdate.image2);
              this.rowInfoUpdate.nativeElement.scrollTop = 0;
              this.titleFormData = '';
              delete this.titleEditSchedule;
            });
            this.image1 = undefined;
            this.image2 = undefined;
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
            this.ticketSelected = _.cloneDeep(this.ticketSelectedClone);
            this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
            return;
          }
        );
        break;
    }
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, false]);
  }

  /**
   * validateBeforeSave
   * @returns
   */
  validateBeforeSave(): boolean {
    const type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    let flag = false;
    if (
      (this.isChooseDetailUpdate &&
        this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule')) ||
      (!this.isChooseDetailUpdate &&
        (this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add') ||
          this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate')))
    ) {
      if (!this.ticketSelected[type].operatorId) {
        this.ticketSelected[type]['isValidateOperatorNameOriginal'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateOperatorNameOriginal'] = false;
      }
      if (!this.ticketSelected[type].appId && (this.titleAdd || this.titleDuplicate)) {
        this.ticketSelected[type]['isValidateAppName'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAppName'] = false;
      }
      if (!this.ticketSelected[type]?.ticketType?.trim()?.length) {
        this.ticketSelected[type]['isValidateTicketType'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateTicketType'] = false;
      }
      if (this.ticketSelected[type].ticketType == 'coupon') {
        //validate couponCount
        if (Helper.isEmpty(this.ticketSelected[type].couponCount)) {
          this.ticketSelected[type]['isValidateCouponCount'] = true;
          flag = true;
        } else if (
          !this.validateHalfsizeNumber(this.ticketSelected[type].couponCount, 1, 9999) ||
          !this.decimalValidator(this.ticketSelected[type].couponCount)
        ) {
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateCouponCount'] = false;
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
        }

        //validate couponGrantingType
        if (!this.ticketSelected[type].couponGrantingType) {
          this.ticketSelected[type]['isValidateCouponGrantingType'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateCouponGrantingType'] = false;
        }
      } else if (
        (!Helper.isEmpty(this.ticketSelected[type].couponCount) &&
          !this.validateHalfsizeNumber(this.ticketSelected[type].couponCount, 1, 9999)) ||
        !this.decimalValidator(this.ticketSelected[type].couponCount)
      ) {
        this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
      }
      if (Helper.isEmpty(this.ticketSelected[type].inUseOperation)) {
        this.ticketSelected[type]['isValidateInUseOperation'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateInUseOperation'] = false;
        if (this.ticketSelected[type].inUseOperation != 2) {
          if (Helper.isEmpty(this.ticketSelected[type].usingPeriod) && this.ticketSelected[type].usingPeriodType) {
            this.ticketSelected[type]['isValidateUsingPeriod'] = true;
            this.ticketSelected[type]['isValidateUsingType'] = false;
            flag = true;
          } else if (!Helper.isEmpty(this.ticketSelected[type].usingPeriod) && !this.ticketSelected[type].usingPeriodType) {
            this.ticketSelected[type]['isValidateUsingPeriod'] = false;
            this.ticketSelected[type]['isValidateUsingType'] = true;
            flag = true;
          } else if (
            !Helper.isEmpty(this.ticketSelected[type].usingPeriod) &&
            this.ticketSelected[type].usingPeriodType == 'D' &&
            (!this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 7) ||
              !this.decimalValidator(this.ticketSelected[type].usingPeriod))
          ) {
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = true;
            flag = true;
          } else if (
            !Helper.isEmpty(this.ticketSelected[type].usingPeriod) &&
            this.ticketSelected[type].usingPeriodType == 'm' &&
            (!this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 5999) ||
              !this.decimalValidator(this.ticketSelected[type].usingPeriod))
          ) {
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
          }
        }
      }

      //availableActiveStartTime
      if (
        this.ticketSelected[type].availableActiveStartTime &&
        !this.validateTimeFormat(this.ticketSelected[type].availableActiveStartTime)
      ) {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
      }

      //availableActiveEndTime
      if (this.ticketSelected[type].availableActiveEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableActiveEndTime)) {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
      }

      //availableShowEndTime
      if (this.ticketSelected[type].availableShowEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableShowEndTime)) {
        this.ticketSelected[type]['isValidateAvailableShowEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
      }

      this.languages.forEach(e => {
        //ticketName
        if (!this.ticketSelected[type]?.ticketName[e.translation_language_code]?.trim()?.length) {
          this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = true;
          flag = true;
        } else {
          if (!this.validateFullsize(this.ticketSelected[type].ticketName[e.translation_language_code], 1, 100)) {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = false;
          }
          this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = false;
        }

        //ticketNameShort
        if (this.ticketSelected[type]?.ticketNameShort[e.translation_language_code]?.trim()?.length) {
          if (!this.validateFullsize(this.ticketSelected[type].ticketNameShort[e.translation_language_code], 0, 50)) {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = false;
          }
        }

        //subtitle
        if (this.ticketSelected[type]?.subtitle[e.translation_language_code]?.trim()?.length) {
          this.ticketSelected[type][`isValidateSubtitleOriginalHalfsize`] = this.validateFullsize(
            this.ticketSelected[type].subtitle[e.translation_language_code],
            0,
            100
          )
            ? false
            : true;
          if (!flag) {
            flag = !this.validateFullsize(this.ticketSelected[type].subtitle[e.translation_language_code], 0, 100);
          }
        }

        //note
        let numberOfCharactersInTheNoteCategory = e.translation_language_code == 'ja' ? 1000 : 2000;
        if (this.ticketSelected[type]?.note[e.translation_language_code]?.trim()?.length) {
          if (!this.validateFullsize(this.ticketSelected[type].note[e.translation_language_code], 0, numberOfCharactersInTheNoteCategory)) {
            this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = false;
          }
        }

        //activateMessage
        if (this.ticketSelected[type].isReserve != true) {
          if (!this.ticketSelected[type]?.activateMessage[e.translation_language_code]?.trim()?.length) {
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = true;
            flag = true;
          } else if (!this.validateFullsize(this.ticketSelected[type].activateMessage[e.translation_language_code], 1, 1000)) {
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = true;
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = false;
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
          }
        }
      });

      if (this.isChooseDetailUpdate) {
        if (!this.date || !this.date?.trim()?.length) {
          this.ticketSelected[type]['isValidateUpdateScheduleAt'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateUpdateScheduleAt'] = false;
        }
      }
      if (this.ticketSelected[type].enableOn && this.ticketSelected[type].enableOn.length) {
        if (this.ticketSelected[type].enableOn.length > 30) {
          this.ticketSelected[type]['isValidateenableOn'] = true;
          flag = true;
        }
      } else {
        this.ticketSelected[type]['isValidateenableOn'] = false;
      }
      if (this.ticketSelected[type].disableOn && this.ticketSelected[type].disableOn.length) {
        if (this.ticketSelected[type].disableOn.length > 30) {
          this.ticketSelected[type]['isValidatedisableOn'] = true;
          flag = true;
        }
      } else {
        this.ticketSelected[type]['isValidatedisableOn'] = false;
      }

      //currencyUnit
      if (!this.ticketSelected[type].currencyUnit) {
        this.ticketSelected[type]['isValidateCurrencyUnit'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateCurrencyUnit'] = false;
      }

      //Adult
      if (this.ticketSelected[type].isInUseAdult && this.ticketSelected[type].isReserve != true) {
        //referencePriceAdult
        if (Helper.isEmpty(this.ticketSelected[type].referencePriceAdult)) {
          this.ticketSelected[type]['isValidateReferencePriceAdult'] = true;
          this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReferencePriceAdult'] = false;
          if (
            this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceAdult, 50, 999999) &&
            this.decimalValidator(this.ticketSelected[type].referencePriceAdult)
          ) {
            this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = true;
            flag = true;
          }
        }
      }

      //Custom1
      if (this.ticketSelected[type].isInUseCustom1) {
        //priceLabelCustom1
        if (
          !this.isChooseDetailUpdate &&
          (this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add') ||
            this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate'))
        ) {
          if (!this.ticketSelected[type].priceLabelCustom1) {
            this.ticketSelected[type]['isValidatePriceLabelCustom1'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidatePriceLabelCustom1'] = false;
            if (this.validateFullsize(this.ticketSelected[type].priceLabelCustom1, 1, 25)) {
              this.ticketSelected[type]['isValidatePriceLabelCustom1MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidatePriceLabelCustom1MinMax'] = true;
              flag = true;
            }
          }
        }

        //referencePriceCustom1
        if (this.ticketSelected[type].isReserve != true) {
          if (Helper.isEmpty(this.ticketSelected[type].referencePriceCustom1)) {
            this.ticketSelected[type]['isValidateReferencePriceCustom1'] = true;
            this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = false;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom1'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom1, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom1)
            ) {
              this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = true;
              flag = true;
            }
          }
        }
      }

      //Custom2
      if (this.ticketSelected[type].isInUseCustom2) {
        //priceLabelCustom2
        if (
          !this.isChooseDetailUpdate &&
          (this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add') ||
            this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate'))
        ) {
          if (!this.ticketSelected[type].priceLabelCustom2) {
            this.ticketSelected[type]['isValidatePriceLabelCustom2'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidatePriceLabelCustom2'] = false;
            if (this.validateFullsize(this.ticketSelected[type].priceLabelCustom2, 1, 25)) {
              this.ticketSelected[type]['isValidatePriceLabelCustom2MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidatePriceLabelCustom2MinMax'] = true;
              flag = true;
            }
          }
        }

        //referencePriceCustom2
        if (this.ticketSelected[type].isReserve != true) {
          if (Helper.isEmpty(this.ticketSelected[type].referencePriceCustom2)) {
            this.ticketSelected[type]['isValidateReferencePriceCustom2'] = true;
            this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = false;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom2'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom2, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom2)
            ) {
              this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = true;
              flag = true;
            }
          }
        }
      }

      //Custom3
      if (this.ticketSelected[type].isInUseCustom3) {
        //priceLabelCustom3
        if (
          !this.isChooseDetailUpdate &&
          (this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add') ||
            this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate'))
        ) {
          if (!this.ticketSelected[type].priceLabelCustom3) {
            this.ticketSelected[type]['isValidatePriceLabelCustom3'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidatePriceLabelCustom3'] = false;
            if (this.validateFullsize(this.ticketSelected[type].priceLabelCustom3, 1, 25)) {
              this.ticketSelected[type]['isValidatePriceLabelCustom3MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidatePriceLabelCustom3MinMax'] = true;
              flag = true;
            }
          }
        }

        //referencePriceCustom3
        if (this.ticketSelected[type].isReserve != true) {
          if (Helper.isEmpty(this.ticketSelected[type].referencePriceCustom3)) {
            this.ticketSelected[type]['isValidateReferencePriceCustom3'] = true;
            this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = false;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom3'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom3, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom3)
            ) {
              this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = true;
              flag = true;
            }
          }
        }
      }

      if (this.ticketSelected[type].ticketType == 'ticket') {
        //isReserve
        if (this.ticketSelected[type].isReserve != undefined && this.ticketSelected[type].isReserve != null) {
          this.ticketSelected[type]['isValidateIsReserve'] = false;
        } else {
          this.ticketSelected[type]['isValidateIsReserve'] = true;
          flag = true;
        }
      }

      if (this.ticketSelected[type].isReserve == true) {
        //reservationAvailablePeriod && reservationAvailablePeriodUnit
        if (
          Helper.isEmpty(this.ticketSelected[type].reservationAvailablePeriod) ||
          !this.ticketSelected[type].reservationAvailablePeriodUnit
        ) {
          this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = true;
          this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
          this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = false;
          if (this.ticketSelected[type].reservationAvailablePeriodUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationAvailablePeriod, 1, 90) &&
              this.decimalValidator(this.ticketSelected[type].reservationAvailablePeriod)
            ) {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = true;
              flag = true;
            }
          } else {
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationAvailablePeriod, 1, 12) &&
              this.decimalValidator(this.ticketSelected[type].reservationAvailablePeriod)
            ) {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = true;
              flag = true;
            }
          }
        }

        //reservationDeadline && reservationDeadlineUnit
        if (Helper.isEmpty(this.ticketSelected[type].reservationDeadline) || !this.ticketSelected[type].reservationDeadlineUnit) {
          this.ticketSelected[type]['isValidateReservationDeadline'] = true;
          this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
          this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReservationDeadline'] = false;
          if (this.ticketSelected[type].reservationDeadlineUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDeadline, 1, 7) &&
              this.decimalValidator(this.ticketSelected[type].reservationDeadline)
            ) {
              this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDeadline-D'] = true;
              flag = true;
            }
          } else {
            this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDeadline, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].reservationDeadline)
            ) {
              this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDeadline-m'] = true;
              flag = true;
            }
          }
        }

        //reservationDisplayStart && reservationDisplayStartUnit
        if (Helper.isEmpty(this.ticketSelected[type].reservationDisplayStart) || !this.ticketSelected[type].reservationDisplayStartUnit) {
          this.ticketSelected[type]['isValidateReservationDisplayStart'] = true;
          this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
          this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReservationDisplayStart'] = false;
          if (this.ticketSelected[type].reservationDisplayStartUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDisplayStart, 0, 7) &&
              this.decimalValidator(this.ticketSelected[type].reservationDisplayStart)
            ) {
              this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = true;
              flag = true;
            }
          } else {
            this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDisplayStart, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].reservationDisplayStart)
            ) {
              this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = true;
              flag = true;
            }
          }
        }
      }
    }
    //TH edit && edit update
    else {
      //Adult
      if (this.ticketSelected[type].isInUseAdult && this.ticketSelected[type].isReserve != true) {
        //referencePriceAdult
        if (Helper.isEmpty(this.ticketSelected[type].referencePriceAdult)) {
          this.ticketSelected[type]['isValidateReferencePriceAdult'] = true;
          this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReferencePriceAdult'] = false;
          if (
            this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceAdult, 50, 999999) &&
            this.decimalValidator(this.ticketSelected[type].referencePriceAdult)
          ) {
            this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = true;
            flag = true;
          }
        }
      }

      //Custom1
      if (this.ticketSelected[type].isInUseCustom1) {
        //priceLabelCustom1
        if (!this.isChooseDetailUpdate && this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit')) {
          if (!this.ticketSelected[type].priceLabelCustom1) {
            this.ticketSelected[type]['isValidatePriceLabelCustom1'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidatePriceLabelCustom1'] = false;
            if (this.validateFullsize(this.ticketSelected[type].priceLabelCustom1, 1, 25)) {
              this.ticketSelected[type]['isValidatePriceLabelCustom1MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidatePriceLabelCustom1MinMax'] = true;
              flag = true;
            }
          }
        }

        //referencePriceCustom1
        if (this.ticketSelected[type].isReserve != true) {
          if (Helper.isEmpty(this.ticketSelected[type].referencePriceCustom1)) {
            this.ticketSelected[type]['isValidateReferencePriceCustom1'] = true;
            this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = false;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom1'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom1, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom1)
            ) {
              this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = true;
              flag = true;
            }
          }
        }
      }

      //Custom2
      if (this.ticketSelected[type].isInUseCustom2) {
        //priceLabelCustom2
        if (!this.isChooseDetailUpdate && this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit')) {
          if (!this.ticketSelected[type].priceLabelCustom2) {
            this.ticketSelected[type]['isValidatePriceLabelCustom2'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidatePriceLabelCustom2'] = false;
            if (this.validateFullsize(this.ticketSelected[type].priceLabelCustom2, 1, 25)) {
              this.ticketSelected[type]['isValidatePriceLabelCustom2MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidatePriceLabelCustom2MinMax'] = true;
              flag = true;
            }
          }
        }

        //referencePriceCustom2
        if (this.ticketSelected[type].isReserve != true) {
          if (Helper.isEmpty(this.ticketSelected[type].referencePriceCustom2)) {
            this.ticketSelected[type]['isValidateReferencePriceCustom2'] = true;
            this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = false;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom2'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom2, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom2)
            ) {
              this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = true;
              flag = true;
            }
          }
        }
      }

      //Custom3
      if (this.ticketSelected[type].isInUseCustom3) {
        //priceLabelCustom3
        if (!this.isChooseDetailUpdate && this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit')) {
          if (!this.ticketSelected[type].priceLabelCustom3) {
            this.ticketSelected[type]['isValidatePriceLabelCustom3'] = true;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidatePriceLabelCustom3'] = false;
            if (this.validateFullsize(this.ticketSelected[type].priceLabelCustom3, 1, 25)) {
              this.ticketSelected[type]['isValidatePriceLabelCustom3MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidatePriceLabelCustom3MinMax'] = true;
              flag = true;
            }
          }
        }

        //referencePriceCustom3
        if (this.ticketSelected[type].isReserve != true) {
          if (Helper.isEmpty(this.ticketSelected[type].referencePriceCustom3)) {
            this.ticketSelected[type]['isValidateReferencePriceCustom3'] = true;
            this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = false;
            flag = true;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom3'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom3, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom3)
            ) {
              this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = false;
            } else {
              this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = true;
              flag = true;
            }
          }
        }
      }

      if (this.ticketSelected[type].isReserve == true) {
        //reservationAvailablePeriod && reservationAvailablePeriodUnit
        if (
          Helper.isEmpty(this.ticketSelected[type].reservationAvailablePeriod) ||
          !this.ticketSelected[type].reservationAvailablePeriodUnit
        ) {
          this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = true;
          this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
          this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = false;
          if (this.ticketSelected[type].reservationAvailablePeriodUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationAvailablePeriod, 1, 90) &&
              this.decimalValidator(this.ticketSelected[type].reservationAvailablePeriod)
            ) {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = true;
              flag = true;
            }
          } else {
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationAvailablePeriod, 1, 12) &&
              this.decimalValidator(this.ticketSelected[type].reservationAvailablePeriod)
            ) {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = true;
              flag = true;
            }
          }
        }

        //reservationDeadline && reservationDeadlineUnit
        if (Helper.isEmpty(this.ticketSelected[type].reservationDeadline) || !this.ticketSelected[type].reservationDeadlineUnit) {
          this.ticketSelected[type]['isValidateReservationDeadline'] = true;
          this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
          this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReservationDeadline'] = false;
          if (this.ticketSelected[type].reservationDeadlineUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDeadline, 1, 7) &&
              this.decimalValidator(this.ticketSelected[type].reservationDeadline)
            ) {
              this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDeadline-D'] = true;
              flag = true;
            }
          } else {
            this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDeadline, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].reservationDeadline)
            ) {
              this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDeadline-m'] = true;
              flag = true;
            }
          }
        }

        //reservationDisplayStart && reservationDisplayStartUnit
        if (Helper.isEmpty(this.ticketSelected[type].reservationDisplayStart) || !this.ticketSelected[type].reservationDisplayStartUnit) {
          this.ticketSelected[type]['isValidateReservationDisplayStart'] = true;
          this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
          this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateReservationDisplayStart'] = false;
          if (this.ticketSelected[type].reservationDisplayStartUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDisplayStart, 0, 7) &&
              this.decimalValidator(this.ticketSelected[type].reservationDisplayStart)
            ) {
              this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = true;
              flag = true;
            }
          } else {
            this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
            if (
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDisplayStart, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].reservationDisplayStart)
            ) {
              this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
            } else {
              this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = true;
              flag = true;
            }
          }
        }
      }

      if (this.ticketSelected[type].inUseOperation != 2) {
        if (Helper.isEmpty(this.ticketSelected[type].usingPeriod) && this.ticketSelected[type].usingPeriodType) {
          this.ticketSelected[type]['isValidateUsingPeriod'] = true;
          this.ticketSelected[type]['isValidateUsingType'] = false;
          flag = true;
        } else if (!Helper.isEmpty(this.ticketSelected[type].usingPeriod) && !this.ticketSelected[type].usingPeriodType) {
          this.ticketSelected[type]['isValidateUsingPeriod'] = false;
          this.ticketSelected[type]['isValidateUsingType'] = true;
          flag = true;
        } else if (
          !Helper.isEmpty(this.ticketSelected[type].usingPeriod) &&
          this.ticketSelected[type].usingPeriodType == 'D' &&
          (!this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 7) ||
            !this.decimalValidator(this.ticketSelected[type].usingPeriod))
        ) {
          this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
          this.ticketSelected[type]['isValidateUsingPeriod-D'] = true;
          flag = true;
        } else if (
          !Helper.isEmpty(this.ticketSelected[type].usingPeriod) &&
          this.ticketSelected[type].usingPeriodType == 'm' &&
          (!this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 5999) ||
            !this.decimalValidator(this.ticketSelected[type].usingPeriod))
        ) {
          this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
          this.ticketSelected[type]['isValidateUsingPeriod-m'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
          this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
        }
      }

      //availableActiveStartTime
      if (
        this.ticketSelected[type].availableActiveStartTime &&
        !this.validateTimeFormat(this.ticketSelected[type].availableActiveStartTime)
      ) {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
      }

      if (this.ticketSelected[type].availableActiveEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableActiveEndTime)) {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = true;
        flag = true;
      } else {
        this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
      }

      //availableShowEndTime
      if (
        this.isChooseDetailUpdate &&
        this.titleFormData == this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule')
      ) {
        if (this.ticketSelected[type].availableShowEndTime && !this.validateTimeFormat(this.ticketSelected[type].availableShowEndTime)) {
          this.ticketSelected[type]['isValidateAvailableShowEndTime'] = true;
          flag = true;
        } else {
          this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
        }
      }

      if (this.ticketSelected[type].enableOn && this.ticketSelected[type].enableOn.length) {
        if (this.ticketSelected[type].enableOn.length > 30) {
          this.ticketSelected[type]['isValidateenableOn'] = true;
          flag = true;
        }
      } else {
        this.ticketSelected[type]['isValidateenableOn'] = false;
      }
      if (this.ticketSelected[type].disableOn && this.ticketSelected[type].disableOn.length) {
        if (this.ticketSelected[type].disableOn.length > 30) {
          this.ticketSelected[type]['isValidatedisableOn'] = true;
          flag = true;
        }
      } else {
        this.ticketSelected[type]['isValidatedisableOn'] = false;
      }

      this.languages.forEach(e => {
        //ticketName
        if (!this.ticketSelected[type]?.ticketName[e.translation_language_code]?.trim()?.length) {
          this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = true;
          flag = true;
        } else {
          if (!this.validateFullsize(this.ticketSelected[type].ticketName[e.translation_language_code], 1, 100)) {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = false;
          }
          this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = false;
        }

        //ticketNameShort
        if (this.ticketSelected[type]?.ticketNameShort[e.translation_language_code]?.trim()?.length) {
          if (!this.validateFullsize(this.ticketSelected[type].ticketNameShort[e.translation_language_code], 0, 50)) {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = false;
          }
        }

        //subtitle
        if (this.ticketSelected[type]?.subtitle[e.translation_language_code]?.trim()?.length) {
          this.ticketSelected[type][`isValidateSubtitleOriginalHalfsize`] = this.validateFullsize(
            this.ticketSelected[type].subtitle[e.translation_language_code],
            0,
            100
          )
            ? false
            : true;
          if (!flag) {
            flag = !this.validateFullsize(this.ticketSelected[type].subtitle[e.translation_language_code], 0, 100);
          }
        }

        //note
        let numberOfCharactersInTheNoteCategory = e.translation_language_code == 'ja' ? 1000 : 2000;
        if (this.ticketSelected[type]?.note[e.translation_language_code]?.trim()?.length) {
          if (!this.validateFullsize(this.ticketSelected[type].note[e.translation_language_code], 0, numberOfCharactersInTheNoteCategory)) {
            this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = true;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = false;
          }
        }

        //activateMessage
        if (this.ticketSelected[type].isReserve != true) {
          if (!this.ticketSelected[type]?.activateMessage[e.translation_language_code]?.trim()?.length) {
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = true;
            flag = true;
          } else if (!this.validateFullsize(this.ticketSelected[type].activateMessage[e.translation_language_code], 1, 1000)) {
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = true;
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
            flag = true;
          } else {
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = false;
            this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
          }
        }
      });
    }
    return flag;
  }

  /**
   * change Checked Day for ticket detail
   * @param id
   * @param e
   */
  changeCheckedDay(key: string, e): void {
    e.stopPropagation();
    const type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    if (this.ticketSelected[`${type}`].enableActivateWeekdays?.includes(key)) {
      const index = this.ticketSelected[`${type}`].enableActivateWeekdays.indexOf(key);
      this.ticketSelected[`${type}`].enableActivateWeekdays.splice(index, 1);
    } else {
      this.ticketSelected[`${type}`].enableActivateWeekdays.push(key);
    }
    // this.checkDates();
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * change State Preview
   */
  changeStatePreview(): void {
    this.isPlay = !this.isPlay;
  }

  /**
   * add New Ticket
   */
  addNewTicket(): void {
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add');
    this.ticketSelected = new Ticket();
    const ticketDetail = new TicketDetail();
    this.mainColor = '#50B4F0';
    ticketDetail.usingPeriodType = 'D';
    ticketDetail.colorCircleUpper = '#282828FF';
    ticketDetail.colorCircleLower = '#282828FF';
    ticketDetail.colorCircleRotation = '#50B4F0FF';
    ticketDetail.colorFontTicket1Top = '#FFFFFF';
    ticketDetail.colorFontTicket1Center = '#FFFFFF';
    ticketDetail.colorFontTicket1Bottom = '#FFFFFF';
    ticketDetail.colorFontTicket2Top = '#000000';
    ticketDetail.colorFontTicket2Center = '#000000';
    ticketDetail.colorFontTicket2Bottom = '#000000';
    ticketDetail.activateMessage['en'] = 'Are you sure to start using this ticket?\nAfter this action the ticket will be non-refundable.';
    ticketDetail.activateMessage['ja'] = 'チケットを使い始めてよろしいですか？\n使用開始後は払い戻しできなくなります。';
    ticketDetail.activateMessage['ko'] = '이 티켓을 사용하시겠습니까?\n활성화 후에는 티켓을 환불할 수 없습니다.';
    ticketDetail.activateMessage['zh'] = ' 您确定要开始使用这张票吗？\n执行此操作后，票券将不可退还';
    ticketDetail.activateMessage['zh-TW'] = '您確定要開始使用這張票嗎？\n執行此操作後，車票將不可退還。';
    ticketDetail.activateMessage['vi'] = 'Bạn có chắc chắn muốn bắt đầu sử dụng vé ? \nVé sử dụng không thể được hoàn tiền';
    ticketDetail.activateMessage['th'] = 'คุณต้องการเริ่มใช้ตั๋วใช่หรือไม่\n\nมื่อยืนยันจะไม่สามารถขอคืนเงินได้';
    ticketDetail.currencyUnit = 'JPY';
    ticketDetail.enableActivateWeekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday'];
    const ticketDetailUpdate = new TicketDetail();
    ticketDetailUpdate.usingPeriodType = 'D';
    ticketDetail.colorCircleUpper = '#282828FF';
    ticketDetail.colorCircleLower = '#282828FF';
    ticketDetail.colorCircleRotation = '#50B4F0FF';
    ticketDetailUpdate.colorFontTicket1Top = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket1Center = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket1Bottom = '#FFFFFF';
    ticketDetailUpdate.colorFontTicket2Top = '#000000';
    ticketDetailUpdate.colorFontTicket2Center = '#000000';
    ticketDetailUpdate.colorFontTicket2Bottom = '#000000';
    ticketDetail.totalCount = ticketDetail.adultCount + ticketDetail.customCount1 + ticketDetail.customCount2 + ticketDetail.customCount3;
    ticketDetail.totalCount ? ticketDetail.totalCount : (ticketDetail.totalCount = 0);
    this.ticketSelected.ticketDetail = ticketDetail;
    ticketDetailUpdate.totalCount =
      ticketDetailUpdate.adultCount + ticketDetailUpdate.customCount1 + ticketDetailUpdate.customCount2 + ticketDetailUpdate.customCount3;
    ticketDetailUpdate.totalCount ? ticketDetailUpdate.totalCount : (ticketDetailUpdate.totalCount = 0);
    this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
    this.titleAdd = this.titleFormData;
    this.isEditTicketDetail = true;
    this.isEditTicketDetailUpdate = false;
    this.isChooseDetailUpdate = false;
    this.languages = Constant.LANGUAGES_SETTING.filter(e => this.languageSelected == e.translation_language_code);
    this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail(true);
    this.changeDetectorRef.detectChanges();
    this.rowInfo.nativeElement.scrollTop = 0;
  }

  /**
   * add New Ticket Update
   */
  addNewTicketUpdate(): void {
    if (!this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
    const ticketDetailUpdate = _.cloneDeep(this.ticketSelected.ticketDetail);
    ticketDetailUpdate.subtitle = _.cloneDeep(this.ticketSelected.ticketDetail.subtitle) ?? {};
    this.ticketSelected.ticketDetailUpdate = ticketDetailUpdate;
    this.titleAddSchedule = this.titleFormData;
    this.isEditTicketDetailUpdate = true;
    this.isEditTicketDetail = false;
    this.isChooseDetailUpdate = true;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail(true);
    this.changeDetectorRef.detectChanges();
    this.rowInfoUpdate.nativeElement.scrollTop = 0;
  }

  /**
   * duplicateTicket
   */
  duplicateTicket(): void {
    if (!this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate');
    this.ticketSelected = _.cloneDeep(this.ticketSelected);
    this.ticketSelected.ticketId = undefined;
    const index = this.listApp.findIndex(e => e.appId == this.ticketSelected?.ticketDetail.appId);
    if (index == -1) {
      this.ticketSelected.appId = null;
      this.ticketSelected.ticketDetail.appId = null;
    }
    this.ticketSelected.ticketId = undefined;
    this.titleDuplicate = this.titleFormData;
    this.isEditTicketDetail = true;
    this.isEditTicketDetailUpdate = false;
    this.isChooseDetailUpdate = false;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail(true);
    this.changeDetectorRef.detectChanges();
    this.rowInfo.nativeElement.scrollTop = 0;
  }

  /**
   * edit Ticket
   */
  editTicket(): void {
    if (!this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit');
    this.titleEdit = this.titleFormData;
    this.isEditTicketDetail = true;
    this.isEditTicketDetailUpdate = false;
    this.isChooseDetailUpdate = false;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    this.openDetail(true);
    this.changeDetectorRef.detectChanges();
    this.rowInfo.nativeElement.scrollTop = 0;
  }

  /**
   * edit Schedule Update
   */
  editTicketUpdate(): void {
    this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
    this.titleEditSchedule = this.titleFormData;
    this.isEditTicketDetailUpdate = true;
    this.isEditTicketDetail = false;
    this.isChooseDetailUpdate = true;
    this.dataService.sendData([Constant.IS_UPDATE_TICKET, true]);
    if (this.ticketSelected.ticketDetailUpdate) {
      if (!this.ticketSelected.ticketDetailUpdate?.subtitle) {
        this.ticketSelected.ticketDetailUpdate.subtitle = {};
      }
    }
    this.openDetail(true);
    this.changeDetectorRef.detectChanges();
    this.rowInfoUpdate.nativeElement.scrollTop = 0;
  }

  /**
   * delete Ticket
   */
  deleteTicket(): void {
    if (!this.ticketDisplays || !this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.ticket-tab.confirm-delete'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        // Delete ticket
        if (!this.ticketDisplays || !this.ticketSelected) {
          return;
        }
        let payload = {
          ticketId: this.ticketSelected.ticketId
        };
        this.ticketService.deleteTicket(this.informationAccount, payload).subscribe(
          data => {
            this.getListTicket();
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
          }
        );
      }
    );
  }

  /**
   * delete Schedule
   */
  deleteScheduleUpdate(): void {
    // let idsDelete = this.tickets?.filter(e => e.isChecked);
    if (!this.ticketDisplays || !this.ticketSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.ticket-tab.confirm-delete-update'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no'),
          title: this.translateService.instant('ticket-editor.tilte-delete-update')
        }
      },
      result => {
        if (!result) {
          return;
        }
        // Delete application
        if (!this.ticketDisplays || !this.ticketSelected) {
          return;
        }
        let payload = {
          ticketId: this.ticketSelected.ticketId
        };
        this.ticketService.deleteTicketUpdate(this.informationAccount, payload).subscribe(
          data => {
            this.getListTicket();
            this.dataService.sendData([Constant.IS_EDITING_TICKET_UPDATTE, false]);
          },
          error => {
            Helper.getErrorTicket(error, this.translateService, this.dialogService);
          }
        );
      }
    );
  }

  /**
   *
   * @param isShow
   */
  openDetail(isShow?: boolean) {
    this.addDetail('TicketName', isShow);
    this.addDetail('NameShort', isShow);
    this.addDetail('Subtitle', isShow);
    this.addDetail('Note', isShow);
    this.addDetail('ActivateMessage', isShow);
    this.addDetail('CompanyName', isShow);
    this.addDetail('OperatorName', isShow);
    this.addDetail('ActivationAvailableDate', isShow);
  }

  /**
   * confirmSaleTicket
   */
  confirmSaleTicket() {
    this.dialogService.showDialog(DialogConfirmSaleTicketComponent, {
      data: {
        tickets: this.saleTickets,
        languageKey: this.languageKey
      }
    });
  }

  /**
   * hide color picker
   */
  public hideColorPicker($event, prop: string): void {
    if (this.focusOn == prop && !this.ticketSelected.ticketDetail[`${prop}-input`]) {
      this.focusOn = '';
    }
  }

  /**
   * Custom Color Hex
   * @param colorHex
   */
  customColorHex(colorHex: string): string {
    const colorCustom = colorHex ? colorHex.substring(1).toLocaleUpperCase() : '';
    return colorCustom;
  }

  /**
   * Change Color By Input
   * @param prop
   * @param event
   */
  changeColorByInput(prop: string, event: any) {
    let color = `#${event.target.value}`;
    if (
      Constant.REGEX_COLOR.test(color) &&
      [
        'colorFontTicket2Bottom',
        'colorFontTicket2Center',
        'colorFontTicket2Top',
        'colorFontTicket1Bottom',
        'colorFontTicket1Center',
        'colorFontTicket1Top'
      ].includes(prop)
    ) {
      this.isChooseDetailUpdate ? (this.ticketSelected.ticketDetailUpdate[prop] = color) : (this.ticketSelected.ticketDetail[prop] = color);
    } else if (
      Constant.REGEX_COLOR_CIRCLE.test(color) &&
      ![
        'colorFontTicket2Bottom',
        'colorFontTicket2Center',
        'colorFontTicket2Top',
        'colorFontTicket1Bottom',
        'colorFontTicket1Center',
        'colorFontTicket1Top'
      ].includes(prop)
    ) {
      this.isChooseDetailUpdate ? (this.ticketSelected.ticketDetailUpdate[prop] = color) : (this.ticketSelected.ticketDetail[prop] = color);
      this.ticketSelected.ticketDetail[prop] = color;
    }
    if (prop.includes('1')) {
      this.isPreview1 = true;
    } else if (prop.includes('2')) {
      this.isPreview1 = false;
    }
    this.handleFocusInput(prop);
    this.focusOn = prop;
  }

  /**
   * changeColorByPicker
   * @param prop
   * @param event
   */
  changeColorByPicker(prop: string, event: any) {
    if (prop.includes('1')) {
      this.isPreview1 = true;
    } else if (prop.includes('2')) {
      this.isPreview1 = false;
    }
    this.isChooseDetailUpdate
      ? (this.ticketSelected.ticketDetailUpdate[`${prop}-input`] = false)
      : (this.ticketSelected.ticketDetail[`${prop}-input`] = false);
    this.focusOn = prop;
  }

  /**
   * handleFocusInput
   * @param prop
   */
  handleFocusInput(prop: string): void {
    const input = [
      'colorCircleUpper',
      'colorCircleRotation',
      'colorCircleLower',
      'colorFontTicket1Top',
      'colorFontTicket1Bottom',
      'colorFontTicket1Center',
      'colorFontTicket2Top',
      'colorFontTicket2Bottom',
      'colorFontTicket2Center'
    ];

    if (this.isChooseDetailUpdate) {
      this.ticketSelected.ticketDetailUpdate[`${prop}-input`] = true;
      input.forEach(e => {
        if (e !== prop) {
          this.ticketSelected.ticketDetailUpdate[`${e}-input`] = false;
        }
      });
    } else {
      this.ticketSelected.ticketDetail[`${prop}-input`] = true;
      input.forEach(e => {
        if (e !== prop) {
          this.ticketSelected.ticketDetail[`${e}-input`] = false;
        }
      });
    }
  }

  public addDetail(property: string, isShow?: boolean): void {
    if (!isShow) {
      this.isChooseDetailUpdate
        ? (this.ticketSelected.ticketDetailUpdate[`isDetail${property}`] = !this.ticketSelected.ticketDetailUpdate[`isDetail${property}`])
        : (this.ticketSelected.ticketDetail[`isDetail${property}`] = !this.ticketSelected.ticketDetail[`isDetail${property}`]);
    } else {
      this.isChooseDetailUpdate
        ? (this.ticketSelected.ticketDetailUpdate[`isDetail${property}`] = true)
        : (this.ticketSelected.ticketDetail[`isDetail${property}`] = true);
    }
  }

  /**
   * Allow Drop
   * @param event
   */
  public allowDrop(event: any): void {
    if (!this.isEditTicketDetail) {
      return;
    }
    event.preventDefault();
  }

  /**
   * Drop media from PC
   * @param file
   * @param isImage1
   */
  public async dropMediaFromPC(e: any, isImage1: boolean): Promise<void> {
    const fileInput = e.target;
    const selectedFile = fileInput.files[0];
    if (!selectedFile || (!this.isEditTicketDetail && !this.isEditTicketDetailUpdate)) {
      return;
    }
    if (
      !(await this.validateImage(
        selectedFile,
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_FILE_SIZE_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_WIDTH_TAB_APPLICATION),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_HEIGHT_TAB_APPLICATION)
      ))
    ) {
      if (isImage1 && this.isEditTicketDetail) {
        this.fileInput1.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetail) {
        this.fileInput2.nativeElement.value = null;
      } else if (isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput3.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput4.nativeElement.value = null;
      }
      return;
    }
    isImage1 ? (this.isPreview1 = true) : (this.isPreview1 = false);
    const mediaType = selectedFile?.name
      .split('.')
      .pop()
      .toLowerCase();
    if (!this.imageTypesAllowed.includes(mediaType)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: this.translateService.instant('index-word-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      if (isImage1 && this.isEditTicketDetail) {
        this.fileInput1.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetail) {
        this.fileInput2.nativeElement.value = null;
      } else if (isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput3.nativeElement.value = null;
      } else if (!isImage1 && this.isEditTicketDetailUpdate) {
        this.fileInput4.nativeElement.value = null;
      }
      return;
    }

    let fileDrop = {
      file: selectedFile,
      url: URL.createObjectURL(selectedFile),
      type: mediaType
    };
    let file = [selectedFile, fileDrop];
    const fileName = `${this.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    if (isImage1) {
      this.image1 = await this.convertFileToBase64(selectedFile as File);
    } else {
      this.image2 = await this.convertFileToBase64(selectedFile as File);
    }
    this.handleAfterDropMedia(mediaType, selectedFile, fileName, file, isImage1);
  }

  /**
   * validateImage
   * @param file
   * @param maxFileSize
   * @param maxWidth
   * @param maxHeight
   * @returns
   */
  validateImage(file: File, maxFileSize: number, maxWidth: number, maxHeight: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!file.type.startsWith('image/')) {
        resolve(false);
        return;
      }

      if (file?.size > maxFileSize * 1024 * 1024) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: Helper.formatString(
              this.translateService.instant(`ticket-editor.msg.maximum-file-size`),
              `${file?.name}`,
              `${maxFileSize}`
            )
          }
        });
        resolve(false);
        return;
      }

      const img = new Image();
      let imageLoaded = false;

      img.onload = () => {
        imageLoaded = true;
        if (img.width > maxWidth) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-width`),
                `${file?.name}`,
                `${maxWidth}`
              )
            }
          });
          resolve(false);
        } else if (img.height > maxHeight) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-height`),
                `${file?.name}`,
                `${maxHeight}`
              )
            }
          });
          resolve(false);
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        if (!imageLoaded) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.common-error')
            }
          });
          resolve(false);
        }
      };

      img.src = URL.createObjectURL(file);
    });
  }

  /**
   * convertFileToBase64
   * @param file
   * @returns
   */
  convertFileToBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  /**
   * Handle After Drop Media
   * @param mediaType
   * @param fileDrop
   * @param file
   * @param isImage1
   */
  private async handleAfterDropMedia(mediaType: any, fileDrop: any, fileName: any, file: any, isImage1: boolean): Promise<void> {
    let fileFromPC: Media = null;
    if (this.isChooseDetailUpdate) {
      isImage1
        ? (this.ticketSelected.ticketDetailUpdate.image1 = fileDrop?.name)
        : (this.ticketSelected.ticketDetailUpdate.image2 = fileDrop?.name);
    } else {
      isImage1 ? (this.ticketSelected.ticketDetail.image1 = fileDrop?.name) : (this.ticketSelected.ticketDetail.image2 = fileDrop?.name);
    }
    this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType, isImage1);
  }

  /**
   * Show Image After Drop
   * @param file
   * @param fileFromPC
   * @param mediaType
   * @param isImage1
   */
  private async showImgAfterDrop(
    fileDrop: any,
    fileName: any,
    file: any,
    fileFromPC: Media,
    mediaType: any,
    isImage1: boolean
  ): Promise<void> {
    let mediaFromPC: Media = new ImageApplicaiton();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    if (this.isChooseDetailUpdate) {
      isImage1
        ? (this.ticketSelected.ticketDetailUpdate.urlImage1 = mediaFromPC.url)
        : (this.ticketSelected.ticketDetailUpdate.urlImage2 = mediaFromPC.url);
    } else {
      isImage1
        ? (this.ticketSelected.ticketDetail.urlImage1 = mediaFromPC.url)
        : (this.ticketSelected.ticketDetail.urlImage2 = mediaFromPC.url);
    }
  }

  /**
   * validateFullsize
   * @param input
   * @param minLength
   * @param maxLength
   * @returns
   */
  validateFullsize(input: string, minLength: number, maxLength: number): boolean {
    if (input?.length < minLength || input?.length > maxLength) {
      return false;
    }
    return true;
  }

  /**
   * validateHalfsizeNumber
   * @param input
   * @returns
   */
  validateHalfsizeNumber(input: number, min: number, max: number): boolean {
    if (isNaN(input)) {
      return false;
    }
    return input >= min && input <= max;
  }

  /**
   * validateTimeFormat
   * @param input
   * @returns
   */
  validateTimeFormat(input: string): boolean {
    const regex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(input);
  }

  /**
   * validateColorCode
   * @param input
   * @returns
   */
  validateColorCode(input: string): boolean {
    const regex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/;
    return regex.test(input);
  }

  /**
   *validateColorCode6
   * @param input
   * @returns
   */
  validateColorCode6(input: string): boolean {
    const regex = /^#([0-9A-Fa-f]{6})$/;
    return regex.test(input);
  }

  /**
   * getStringTranslate
   * @param msg
   * @param param
   * @param param2
   * @returns
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`ticket-editor.ticket-tab.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`ticket-editor.ticket-tab.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * validate input
   * @param prop
   */
  public validateInput(prop: string): void {
    const type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    switch (prop) {
      case 'OperatorName':
        const index = this.listOperators.findIndex(e => e.operatorId == this.ticketSelected?.ticketDetail.operatorId);
        if (index !== -1) {
          let operator = this.listOperators[index]?.operatorName;
          this.ticketSelected[type].operatorName = operator;
        }
        this.ticketSelected[type]['isValidateOperatorNameOriginal'] = this.ticketSelected[type].operatorId ? false : true;
        break;
      case 'AppName':
        const oldLanguages = _.cloneDeep(this.languages);
        this.ticketSelected[type]['isValidateAppName'] = this.ticketSelected[type].appId ? false : true;
        if (this.ticketSelected[type]['isValidateAppName']) {
          this.languages = Constant.LANGUAGES_SETTING.filter(e => this.languageSelected == e.translation_language_code);
        } else {
          const index = this.listApp.findIndex(e => e.appId == this.ticketSelected?.ticketDetail.appId);
          if (index !== -1) {
            const newlanguagesCodes = Helper.getLanguagesCode(this.listApp[index].supportedLanguage, this.commonService);
            this.languages = newlanguagesCodes.map(code =>
              Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code)
            );
            this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
            this.languageSelectedPreview = _.cloneDeep(this.languages[0].translation_language_code);
            this.ticketService.getApplicationDesigns(this.ticketSelected?.ticketDetail.appId, this.informationAccount).subscribe(data => {
              const index = data.findIndex(e => e.applied == true);
              if (index != -1) {
                this.mainColor = data[index].mainColor;
                this.changeDetectorRef.detectChanges();
              }
            });
          }
          oldLanguages.forEach(e => {
            if (!this.languages.some(lang => lang.translation_language_code === e.translation_language_code)) {
              this.ticketSelected[type].ticketName[e.translation_language_code] = '';
              this.ticketSelected[type].ticketNameShort[e.translation_language_code] = '';
              this.ticketSelected[type].subtitle[e.translation_language_code] = '';
              this.ticketSelected[type].note[e.translation_language_code] = '';
              this.ticketSelected[type].activateMessage['en'] =
                'Are you sure to start using this ticket?\nAfter this action the ticket will be non-refundable.';
              this.ticketSelected[type].activateMessage['ja'] =
                'チケットを使い始めてよろしいですか？\n使用開始後は払い戻しできなくなります。';
              this.ticketSelected[type].activateMessage['ko'] = '이 티켓을 사용하시겠습니까?\n활성화 후에는 티켓을 환불할 수 없습니다.';
              this.ticketSelected[type].activateMessage['zh'] = ' 您确定要开始使用这张票吗？\n执行此操作后，票券将不可退还';
              this.ticketSelected[type].activateMessage['zh-TW'] = '您確定要開始使用這張票嗎？\n執行此操作後，車票將不可退還。';
              this.ticketSelected[type].activateMessage['vi'] =
                'Bạn có chắc chắn muốn bắt đầu sử dụng vé ? \nVé sử dụng không thể được hoàn tiền';
              this.ticketSelected[type].activateMessage['th'] = 'คุณต้องการเริ่มใช้ตั๋วใช่หรือไม่\n\nมื่อยืนยันจะไม่สามารถขอคืนเงินได้';
              if (!['en', 'ja', 'ko', 'zh', 'zh-TW', 'vi', 'th'].includes(e.translation_language_code)) {
                this.ticketSelected[type].activateMessage[e.translation_language_code] = '';
              }
              this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}`] = false;
              this.ticketSelected[type][`isValidateTicketName${e.translation_language_code}Halfsize`] = false;
              this.ticketSelected[type][`isValidateTicketNameShort${e.translation_language_code}Halfsize`] = false;
              this.ticketSelected[type][`isValidateSubtitle${e.translation_language_code}Halfsize`] = false;
              this.ticketSelected[type][`isValidateNote${e.translation_language_code}Halfsize`] = false;
              this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}`] = false;
              this.ticketSelected[type][`isValidateActivateMessage${e.translation_language_code}Halfsize`] = false;
            }
          });
        }
        break;
      case 'TicketType':
        this.ticketSelected[type]['isValidateTicketType'] = this.ticketSelected[type]?.ticketType?.trim()?.length ? false : true;
        if (!this.ticketSelected[type]['isValidateTicketType']) {
          //type == ticket
          if (this.ticketSelected[type].ticketType == 'ticket') {
            if (this.isCoupon) {
              this.setDefault(type);
            }
            this.isCoupon = false;
            this.ticketSelected[type].inUseOperation = 0;
            this.ticketSelected[type]['isValidateInUseOperation'] = false;
            this.ticketSelected[type]['isValidateCouponCount'] = false;
            this.ticketSelected[type]['isValidateCouponGrantingType'] = false;
            this.ticketSelected[type].isReserve = true;
            this.validateInput('isReserve');
            this.setDefaultIsReserve(type);
          } else {
            //type == coupon
            this.setDefault(type);
            this.isCoupon = true;
            this.ticketSelected[type].inUseOperation = 2;
            this.ticketSelected[type]['isValidateInUseOperation'] = false;
            this.ticketSelected[type].isReserve = null;
            this.setDefaultIsReserve(type);
          }
        } else {
          if (this.isCoupon) {
            this.setDefault(type);
          }
          this.isCoupon = false;
          this.ticketSelected[type].inUseOperation = null;
          this.ticketSelected[type]['isValidateCouponCount'] = false;
          this.ticketSelected[type]['isValidateCouponGrantingType'] = false;
          this.ticketSelected[type].isReserve = null;
          this.setDefaultIsReserve(type);
        }
        break;
      case 'CouponGrantingType':
        this.ticketSelected[type]['isValidateCouponGrantingType'] = this.ticketSelected[type].couponGrantingType ? false : true;
        break;
      case 'InUseOperation':
        if (Helper.isEmpty(this.ticketSelected[type].inUseOperation)) {
          this.setDefault(type, this.ticketSelected[type].ticketType == 'coupon');
          this.ticketSelected[type]['isValidateInUseOperation'] = true;
          this.setDefault(type, this.ticketSelected[type].ticketType == 'coupon');
        } else {
          if (this.ticketSelected[type].inUseOperation == 2) {
            this.setDefault(type, true);
            this.ticketSelected[type]['isValidateUsingType'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
          }
          this.ticketSelected[type]['isValidateInUseOperation'] = false;
        }
        break;
      case 'CouponCount':
        if (this.ticketSelected[type].ticketType == 'coupon') {
          this.ticketSelected[type]['isValidateCouponCount'] = this.ticketSelected[type].couponCount ? false : true;
          if (this.ticketSelected[type]['isValidateCouponCount'] == false) {
            this.ticketSelected[type]['isValidateCouponCount_Halfsize'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].couponCount, 1, 100) &&
              this.decimalValidator(this.ticketSelected[type].couponCount)
                ? false
                : true;
          }
        } else if (!Helper.isEmpty(this.ticketSelected[type].couponCount)) {
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] =
            this.validateHalfsizeNumber(this.ticketSelected[type].couponCount, 1, 100) &&
            this.decimalValidator(this.ticketSelected[type].couponCount)
              ? false
              : true;
        } else {
          this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
        }
        break;
      case 'UsingPeriod':
        this.restrictToNumbers(type, 'usingPeriod');
        if (!Helper.isEmpty(this.ticketSelected[type].usingPeriod)) {
          this.ticketSelected[type]['isValidateUsingPeriod'] = false;
          if (this.ticketSelected[type].usingPeriodType == 'D') {
            this.ticketSelected[type]['isValidateUsingType'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-D'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 7) &&
              this.decimalValidator(this.ticketSelected[type].usingPeriod)
                ? false
                : true;
          } else if (this.ticketSelected[type].usingPeriodType == 'm') {
            this.ticketSelected[type]['isValidateUsingType'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].usingPeriod, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].usingPeriod)
                ? false
                : true;
          } else {
            this.ticketSelected[type]['isValidateUsingType'] = true;
            this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
            this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
          }
        } else {
          if (this.ticketSelected[type].usingPeriodType) {
            this.ticketSelected[type]['isValidateUsingPeriod'] = true;
            this.ticketSelected[type]['isValidateUsingType'] = false;
          } else {
            this.ticketSelected[type]['isValidateUsingPeriod'] = false;
            this.ticketSelected[type]['isValidateUsingType'] = false;
          }
          this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
          this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
        }
        break;
      case 'usingPeriodType':
        if (!this.ticketSelected[type].usingPeriodType) {
          this.ticketSelected[type]['isValidateUsingType'] = !Helper.isEmpty(this.ticketSelected[type].usingPeriod) ? true : false;
          this.validateInput('UsingPeriod');
        } else {
          this.ticketSelected[type]['isValidateUsingType'] = false;
          this.validateInput('UsingPeriod');
        }
        break;
      case 'AvailableActiveStartTime':
        //AvailableActiveStartTime
        if (this.ticketSelected[type].availableActiveStartTime) {
          this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = this.validateTimeFormat(
            this.ticketSelected[type].availableActiveStartTime
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
        }
        break;
      case 'AvailableActiveEndTime':
        if (this.ticketSelected[type].availableActiveEndTime) {
          this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = this.validateTimeFormat(
            this.ticketSelected[type].availableActiveEndTime
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
        }
        break;
      case 'AvailableShowEndTime':
        // this.ticketSelected[type]['isValidateAvailableShowEndTimeNotNull'] = this.ticketSelected[type].availableShowEndTime ? false : true;
        if (this.ticketSelected[type].availableShowEndTime) {
          this.ticketSelected[type]['isValidateAvailableShowEndTime'] = this.validateTimeFormat(
            this.ticketSelected[type].availableShowEndTime
          )
            ? false
            : true;
        } else {
          this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
        }
        break;
      case 'currencyUnit':
        this.ticketSelected[type]['isValidateCurrencyUnit'] = this.ticketSelected[type].currencyUnit ? false : true;
        break;
      case 'referencePriceAdult':
        if (this.ticketSelected[type].isReserve != true) {
          this.ticketSelected[type]['isValidateReferencePriceAdult'] = !Helper.isEmpty(this.ticketSelected[type].referencePriceAdult)
            ? false
            : true;
          if (this.ticketSelected[type]['isValidateReferencePriceAdult']) {
            this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceAdult, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceAdult)
                ? false
                : true;
          }
        }
        break;
      case 'priceLabelCustom1':
        this.ticketSelected[type]['isValidatePriceLabelCustom1'] = this.ticketSelected[type].priceLabelCustom1 ? false : true;
        if (this.ticketSelected[type]['isValidatePriceLabelCustom1']) {
          this.ticketSelected[type]['isValidatePriceLabelCustom1MinMax'] = false;
        } else {
          this.ticketSelected[type]['isValidatePriceLabelCustom1MinMax'] = this.validateFullsize(
            this.ticketSelected[type].priceLabelCustom1,
            1,
            25
          )
            ? false
            : true;
        }
        break;
      case 'referencePriceCustom1':
        if (this.ticketSelected[type].isReserve != true) {
          this.ticketSelected[type]['isValidateReferencePriceCustom1'] = !Helper.isEmpty(this.ticketSelected[type].referencePriceCustom1)
            ? false
            : true;
          if (this.ticketSelected[type]['isValidateReferencePriceCustom1']) {
            this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = false;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom1, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom1)
                ? false
                : true;
          }
        }
        break;
      case 'priceLabelCustom2':
        this.ticketSelected[type]['isValidatePriceLabelCustom2'] = this.ticketSelected[type].priceLabelCustom2 ? false : true;
        if (this.ticketSelected[type]['isValidatePriceLabelCustom2']) {
          this.ticketSelected[type]['isValidatePriceLabelCustom2MinMax'] = false;
        } else {
          this.ticketSelected[type]['isValidatePriceLabelCustom2MinMax'] = this.validateFullsize(
            this.ticketSelected[type].priceLabelCustom2,
            1,
            25
          )
            ? false
            : true;
        }
        break;
      case 'referencePriceCustom2':
        if (this.ticketSelected[type].isReserve != true) {
          this.ticketSelected[type]['isValidateReferencePriceCustom2'] = !Helper.isEmpty(this.ticketSelected[type].referencePriceCustom2)
            ? false
            : true;
          if (this.ticketSelected[type]['isValidateReferencePriceCustom2']) {
            this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = false;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom2, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom2)
                ? false
                : true;
          }
        }
        break;
      case 'priceLabelCustom3':
        this.ticketSelected[type]['isValidatePriceLabelCustom3'] = this.ticketSelected[type].priceLabelCustom3 ? false : true;
        if (this.ticketSelected[type]['isValidatePriceLabelCustom3']) {
          this.ticketSelected[type]['isValidatePriceLabelCustom3MinMax'] = false;
        } else {
          this.ticketSelected[type]['isValidatePriceLabelCustom3MinMax'] = this.validateFullsize(
            this.ticketSelected[type].priceLabelCustom3,
            1,
            25
          )
            ? false
            : true;
        }
        break;
      case 'referencePriceCustom3':
        if (this.ticketSelected[type].isReserve != true) {
          this.ticketSelected[type]['isValidateReferencePriceCustom3'] = !Helper.isEmpty(this.ticketSelected[type].referencePriceCustom3)
            ? false
            : true;
          if (this.ticketSelected[type]['isValidateReferencePriceCustom3']) {
            this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = false;
          } else {
            this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].referencePriceCustom3, 50, 999999) &&
              this.decimalValidator(this.ticketSelected[type].referencePriceCustom3)
                ? false
                : true;
          }
        }
        break;
      case 'isReserve':
        this.ticketSelected[type]['isValidateIsReserve'] =
          this.ticketSelected[type].isReserve != undefined && this.ticketSelected[type].isReserve != null ? false : true;
        if (this.ticketSelected[type].isReserve == true) {
          this.ticketSelected[type].inUseOperation = 3;
          this.ticketSelected[type]['isValidateInUseOperation'] = false;
          this.ticketSelected[type].availableActiveStartTime = null;
          this.ticketSelected[type].enableActivateWeekdays = [];
          this.ticketSelected[type].enableOn = [];
          this.ticketSelected[type].disableOn = [];
          this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
          this.ticketSelected[type].availableActiveEndTime = null;
          this.ticketSelected[type]['isValidateAvailableActiveEndTime'] = false;
          this.ticketSelected[type].referencePriceAdult = null;
          this.ticketSelected[type]['isValidateReferencePriceAdult'] = false;
          this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
          this.ticketSelected[type].referencePriceCustom1 = null;
          this.ticketSelected[type]['isValidateReferencePriceCustom1'] = false;
          this.ticketSelected[type]['isValidateReferencePriceCustom1MinMax'] = false;
          this.ticketSelected[type].referencePriceCustom2 = null;
          this.ticketSelected[type]['isValidateReferencePriceCustom2'] = false;
          this.ticketSelected[type]['isValidateReferencePriceCustom2MinMax'] = false;
          this.ticketSelected[type].referencePriceCustom3 = null;
          this.ticketSelected[type]['isValidateReferencePriceCustom3'] = false;
          this.ticketSelected[type]['isValidateReferencePriceCustom3MinMax'] = false;
        } else {
          if (this.ticketSelected[type].ticketType) {
            if (this.ticketSelected[type].ticketType == 'ticket') {
              this.ticketSelected[type].inUseOperation = 0;
              this.ticketSelected[type]['isValidateInUseOperation'] = false;
            } else {
              this.ticketSelected[type].inUseOperation = 2;
              this.ticketSelected[type]['isValidateInUseOperation'] = false;
            }
          } else {
            this.ticketSelected[type].inUseOperation = null;
          }
          this.setDefaultIsReserve(type);
        }
        break;
      case 'reservationAvailablePeriod':
        this.restrictToNumbers(type, 'reservationAvailablePeriod');
        if (
          Helper.isEmpty(this.ticketSelected[type].reservationAvailablePeriod) ||
          !this.ticketSelected[type].reservationAvailablePeriodUnit
        ) {
          this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = true;
          this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
          this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
        } else {
          this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = false;
          if (this.ticketSelected[type].reservationAvailablePeriodUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationAvailablePeriod, 1, 90) &&
              this.decimalValidator(this.ticketSelected[type].reservationAvailablePeriod)
                ? false
                : true;
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
          } else {
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
            this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationAvailablePeriod, 1, 12) &&
              this.decimalValidator(this.ticketSelected[type].reservationAvailablePeriod)
                ? false
                : true;
          }
        }
        break;
      case 'reservationDeadline':
        this.restrictToNumbers(type, 'reservationDeadline');
        if (Helper.isEmpty(this.ticketSelected[type].reservationDeadline) || !this.ticketSelected[type].reservationDeadlineUnit) {
          this.ticketSelected[type]['isValidateReservationDeadline'] = true;
          this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
          this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
        } else {
          this.ticketSelected[type]['isValidateReservationDeadline'] = false;
          if (this.ticketSelected[type].reservationDeadlineUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationDeadline-D'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDeadline, 1, 7) &&
              this.decimalValidator(this.ticketSelected[type].reservationDeadline)
                ? false
                : true;
            this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
          } else {
            this.ticketSelected[type]['isValidateReservationDeadline-m'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDeadline, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].reservationDeadline)
                ? false
                : true;
            this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
          }
        }
        break;
      case 'reservationDisplayStart':
        this.restrictToNumbers(type, 'reservationDisplayStart');
        if (Helper.isEmpty(this.ticketSelected[type].reservationDisplayStart) || !this.ticketSelected[type].reservationDisplayStartUnit) {
          this.ticketSelected[type]['isValidateReservationDisplayStart'] = true;
          this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
          this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
        } else {
          this.ticketSelected[type]['isValidateReservationDisplayStart'] = false;
          if (this.ticketSelected[type].reservationDisplayStartUnit == 'days') {
            this.ticketSelected[type]['isValidateReservationDisplayStart-D'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDisplayStart, 0, 7) &&
              this.decimalValidator(this.ticketSelected[type].reservationDisplayStart)
                ? false
                : true;
            this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
          } else {
            this.ticketSelected[type]['isValidateReservationDisplayStart-m'] =
              this.validateHalfsizeNumber(this.ticketSelected[type].reservationDisplayStart, 1, 5999) &&
              this.decimalValidator(this.ticketSelected[type].reservationDisplayStart)
                ? false
                : true;
            this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
          }
        }
        break;
      default:
        if (prop.includes('TicketName') && !prop.includes('TicketNameShort')) {
          let language = prop.slice('TicketName'?.length);
          if (!this.convertLanguagesToLanguageStringArray(this.languages)?.includes(language)) {
            return;
          }
          this.ticketSelected[type][`isValidate${prop}`] = this.ticketSelected[type]?.ticketName[language]?.trim()?.length ? false : true;
          if (!this.ticketSelected[type][`isValidate${prop}`]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].ticketName[language],
              1,
              100
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.includes('TicketNameShort')) {
          let language = prop.slice('TicketNameShort'?.length);
          if (!this.convertLanguagesToLanguageStringArray(this.languages)?.includes(language)) {
            return;
          }
          if (this.ticketSelected[type].ticketNameShort[language]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].ticketNameShort[language],
              0,
              50
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.startsWith('Subtitle')) {
          let language = prop.slice('Subtitle'?.length);
          if (!this.convertLanguagesToLanguageStringArray(this.languages)?.includes(language)) {
            return;
          }
          if (this.ticketSelected[type]?.subtitle[language]?.trim()?.length) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].subtitle[language],
              0,
              100
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.includes('Note')) {
          let language = prop.slice('Note'?.length);
          if (!this.convertLanguagesToLanguageStringArray(this.languages)?.includes(language)) {
            return;
          }
          let numberOfCharactersInTheNoteCategory = language == 'ja' ? 1000 : 2000;
          if (this.ticketSelected[type].note[language]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].note[language],
              0,
              numberOfCharactersInTheNoteCategory
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
        if (prop.includes('ActivateMessage')) {
          let language = prop.slice('ActivateMessage'?.length);
          if (!this.convertLanguagesToLanguageStringArray(this.languages)?.includes(language)) {
            return;
          }
          this.ticketSelected[type][`isValidate${prop}`] = this.ticketSelected[type]?.activateMessage[language]?.trim()?.length
            ? false
            : true;
          if (!this.ticketSelected[type][`isValidate${prop}`]) {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = this.validateFullsize(
              this.ticketSelected[type].activateMessage[language],
              1,
              100
            )
              ? false
              : true;
          } else {
            this.ticketSelected[type][`isValidate${prop}Halfsize`] = false;
          }
        }
    }
  }

  /**
   * check Validation Price
   * @param properties
   * @returns
   */
  private checkValidationPrice(properties: string): boolean {
    const property = properties.split('.');
    if (this.isChooseDetailUpdate) {
      return (
        this.ticketSelected.ticketDetailUpdate[property[0]][property[1]] == null ||
        this.ticketSelected.ticketDetailUpdate[property[0]][property[1]] < 0 ||
        this.ticketSelected.ticketDetailUpdate[property[0]][property[1]] > 9999
      );
    } else {
      return (
        this.ticketSelected.ticketDetail[property[0]][property[1]] == null ||
        this.ticketSelected.ticketDetail[property[0]][property[1]] < 0 ||
        this.ticketSelected.ticketDetail[property[0]][property[1]] > 9999
      );
    }
  }

  /**
   * changePreview
   */
  public changePreview(isPreview1: boolean): void {
    this.isPreview1 = isPreview1;
  }

  /**
   * focusOut
   */
  focusOut(str?: string): void {
    this.focusOn = '';
    if (str) {
      this.validateInput(`${str}`);
    }
  }

  /**
   * convertColorToRgba
   * @param event
   * @param prop
   */
  convertColorToRgba(event: any, prop: string): void {
    // Remove the '#' if it's present
    let hexColor = event.color.replace('#', '');

    // Convert opacity to a valid range (0 to 1)
    const validOpacity = Math.max(0, Math.min(1, +event.opacity));

    // Convert the hex color to RGB
    const bigint = parseInt(hexColor, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Convert the components to hexadecimal and pad with zeros if needed
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    const hexOpacity = Math.round(validOpacity * 255)
      .toString(16)
      .padStart(2, '0');

    // Create the 8-character hex color code
    const hexWithOpacity = `#${hexR}${hexG}${hexB}${hexOpacity}`;
    this.isChooseDetailUpdate
      ? (this.ticketSelected.ticketDetailUpdate[`${prop}`] = hexWithOpacity.toLocaleUpperCase())
      : (this.ticketSelected.ticketDetail[`${prop}`] = hexWithOpacity.toLocaleUpperCase());
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * changeDetailUpdate
   * @param isChooseDetailUpdate
   */
  changeDetailUpdate(isChooseDetailUpdate: boolean): void {
    this.isChooseDetailUpdate = isChooseDetailUpdate;
    if (this.isEditTicketDetail || this.isEditTicketDetailUpdate) {
      this.isEditTicketDetail = isChooseDetailUpdate ? false : true;
      this.isEditTicketDetailUpdate = isChooseDetailUpdate ? true : false;
    }
  }

  /**
   * Set Date
   * @param event
   * @param itemName
   */
  convertToDate(date: string, isUpdateAt?: boolean): Date {
    if (!date) {
      return null;
    }
    if (isUpdateAt) {
      return new Date(this.date);
    }
    return new Date(date);
  }

  /**
   * changeTitleFormData
   */
  changeTitleFormData() {
    if (this.titleFormData) {
      switch (this.titleFormData) {
        case this.titleAdd:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add');
          break;
        case this.titleAddSchedule:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.add-schedule');
          break;
        case this.titleEdit:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit');
          break;
        case this.titleEditSchedule:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.edit-schedule');
          break;
        case this.titleDuplicate:
          this.titleFormData = this.translateService.instant('menu-ticket-editor.edit.tab-ticket.duplicate');
          break;
      }
    }
  }

  translateTicket(): void {
    // const type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    // if (this.ticketSelected[`${type}`].ticketName.original) {
    //   const keyLanguage = this.languageKey == 'en' ? this.languageKey : 'ja';
    //   const targetCode = [];
    //   for(let property of Object.keys(this.ticketSelected[`${type}`])) {
    //     if(property.includes("isCheckTicketName") && this.ticketSelected[`${type}`][`${property}`]) {
    //     targetCode.push(property.slice(-2));
    //     }
    //   }
    //   if(targetCode.length) {
    //     let payload = {
    //       base_language_code: keyLanguage,
    //       text: Helper.encodeHTMLAnnouncementManager(this.ticketSelected[`${type}`].ticketName.original),
    //       source_language_code: keyLanguage,
    //       target_language_code: targetCode,
    //       glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_ticket_edito` : ''
    //     };
    //   }
    // }
  }

  changeCheckTranslation(property: string, caseValue: string): void {
    let type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    if (caseValue == 'TicketName') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('TicketName'?.length);
      if (!Object.keys(this.ticketSelected[type]?.ticketName).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].ticketName[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateTicketName') && !e.includes('isValidateTicketNameOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'TicketNameShort') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('TicketNameShort'?.length);
      if (!Object.keys(this.ticketSelected[type]?.ticketNameShort).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].ticketNameShort[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateTicketNameShort') && !e.includes('isValidateTicketNameShortOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'Subtitle') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('Subtitle'?.length);
      if (!Object.keys(this.ticketSelected[type]?.subtitle).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].subtitle[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateSubtitle') && !e.includes('isValidateSubtitleOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'Note') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('Note'?.length);
      if (!Object.keys(this.ticketSelected[type]?.note).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].note[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateNote') && !e.includes('isValidateNoteOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    } else if (caseValue == 'ActivateMessage') {
      this.ticketSelected[type][`isCheck${property}`] = !this.ticketSelected[type][`isCheck${property}`];
      let language = property.slice('ActivateMessage'?.length);
      if (!Object.keys(this.ticketSelected[type]?.activateMessage).includes(language) && this.ticketSelected[type][`isCheck${property}`]) {
        this.ticketSelected[type].activateMessage[language] = '';
      }
      if (!this.ticketSelected[type][`isCheck${property}`]) {
        for (let e of Object.keys(this.ticketSelected[type])) {
          if (e.includes('isValidateActivateMessage') && !e.includes('isValidateActivateMessageOriginal')) {
            delete this.ticketSelected[type][e];
          }
        }
      }
    }
  }

  /**
   * checkDisplayInput
   * @returns
   */
  checkDisplayInput(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (this.titleEdit) {
      return false;
    }
    if (this.titleAdd || this.titleDuplicate) {
      return true;
    }
  }

  /**
   * checkDisplayDate
   * @returns
   */
  checkDisplayDate(): boolean {
    if (!this.titleFormData) {
      return false;
    }
    if (this.titleEdit) {
      return false;
    }
    if (this.titleAdd || this.titleDuplicate || this.titleEditSchedule || this.titleAddSchedule) {
      return true;
    }
  }

  /**
   * getOperatorName
   * @param operator
   * @returns
   */
  getOperatorName(operator: any): string {
    if (this.languageKey == 'en') {
      return operator?.operatorName.en ? operator?.operatorName.en : operator?.operatorId;
    } else {
      return operator?.operatorName.ja ? operator?.operatorName.ja : operator?.operatorId;
    }
  }

  /**
   * getValidityPeriod
   * @param ticket
   * @returns
   */
  getValidityPeriod(ticket: any): string {
    let type = ticket.usingPeriodType == 'D' ? '日' : '分';
    if (!Helper.isEmpty(ticket.usingPeriod)) {
      return `${ticket.usingPeriod}${type}`;
    } else {
      return '';
    }
  }

  /**
   * setDate
   * @param event
   * @param itemName
   */
  setDate(event: any, itemName: string): void {
    const type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';
    if (event) {
      switch (itemName) {
        case 'enableOn[0]':
          this.ticketSelected[type].enableOn[0] = event;
          break;
        case 'enableOn[1]':
          if (!this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1]) {
            this.ticketSelected[type].enableOn = ['', event];
          } else if (this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1]) {
            this.ticketSelected[type].enableOn.push(event);
          } else {
            this.ticketSelected[type].enableOn[1] = event;
          }
          break;
        case 'disableOn[0]':
          this.ticketSelected[type].disableOn[0] = event;
          break;
        case 'disableOn[1]':
          if (!this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1]) {
            this.ticketSelected[type].disableOn = ['', event];
          } else if (this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1]) {
            this.ticketSelected[type].disableOn.push(event);
          } else {
            this.ticketSelected[type].disableOn[1] = event;
          }
          break;
        default:
          break;
      }
    }

    this.ticketSelected[type]['isValidateEnableOn0'] =
      !this.ticketSelected[type]?.enableOn?.[0] && this.ticketSelected[type]?.enableOn?.[1] ? true : false;
    this.ticketSelected[type]['isValidateEnableOn1'] =
      this.ticketSelected[type]?.enableOn?.[0] && !this.ticketSelected[type]?.enableOn?.[1] ? true : false;
    if (this.ticketSelected[type]?.enableOn?.[0] && this.ticketSelected[type]?.enableOn?.[1]) {
      const dateEnableOnStart = new Date(this.ticketSelected[type]?.enableOn?.[0]);
      const dateEnableOnEnd = new Date(this.ticketSelected[type]?.enableOn?.[1]);
      this.ticketSelected[type]['isValidateEnableOnValid'] = dateEnableOnStart > dateEnableOnEnd ? true : false;
    }

    this.ticketSelected[type]['isValidateDisableOn0'] =
      !this.ticketSelected[type]?.disableOn?.[0] && this.ticketSelected[type]?.disableOn?.[1] ? true : false;
    this.ticketSelected[type]['isValidateDisableOn1'] =
      this.ticketSelected[type]?.disableOn?.[0] && !this.ticketSelected[type]?.disableOn?.[1] ? true : false;
    if (this.ticketSelected[type]?.disableOn?.[0] && this.ticketSelected[type]?.disableOn?.[1]) {
      const dateDisableOnStart = new Date(this.ticketSelected[type]?.disableOn?.[0]);
      const dateDisableOnEnd = new Date(this.ticketSelected[type]?.disableOn?.[1]);
      this.ticketSelected[type]['isValidateDisableOnValid'] = dateDisableOnStart > dateDisableOnEnd ? true : false;
    }
  }

  /**
   * getInUseOperation
   * @param input
   * @returns
   */
  getInUseOperation(input: number): string {
    let inUseOperation;
    if (input == undefined || input == null) {
      return;
    }
    switch (input) {
      case 0:
        inUseOperation = '有効化ボタン';
        break;
      case 1:
        inUseOperation = 'QR表示';
        break;
      case 2:
        inUseOperation = 'QR読み取り・コード入力';
        break;
      case 3:
        inUseOperation = '自動有効化';
        break;
    }
    return inUseOperation;
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayScheduleAt(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 16).replace('T', ' ');
  }

  /**
   * changeTime
   * @param e
   */
  changeTime(e: any) {
    this.time = e;
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp?.length > 28) {
      value = value.substring(0, 28) + '...';
    } else if (value?.length > 50) {
      value = value.substring(0, 50) + '...';
    }
    return value;
  }

  /**
   * getAppName
   * @param appId
   * @returns
   */
  getAppName(appId: string): string {
    const index = this.listApp.findIndex(e => e.appId == appId);
    if (index == -1) {
      return '';
    }
    return this.listApp[index].appName;
  }

  /**
   * getFileNameFromUrl
   * @param url
   * @returns
   */
  getFileNameFromUrl(url: string): string {
    if (!url || (url && url.length == 0)) {
      return '';
    }
    const lastSlashIndex = url?.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      return url;
    }
    const filenameWithExtension = url.substring(lastSlashIndex + 1, url.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    return filename;
  }

  /**
   * setDefault
   * @param type
   */
  setDefault(type, hasCoupon?: any): void {
    this.ticketSelected[type].usingPeriod = null;
    this.ticketSelected[type]['isValidateUsingType'] = false;
    this.ticketSelected[type]['isValidateUsingPeriod-D'] = false;
    this.ticketSelected[type]['isValidateUsingPeriod-m'] = false;
    this.ticketSelected[type].usingPeriodType = 'D';
    this.ticketSelected[type]['isValidateUsingPeriod'] = false;
    this.ticketSelected[type].availableShowEndTime = null;
    this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
    //select inUseOperation == 2 => no reset
    if (!hasCoupon) {
      this.ticketSelected[type].couponGrantingType = null;
      this.ticketSelected[type]['isValidateCouponGrantingType'] = false;
      this.ticketSelected[type].couponCount = null;
      this.ticketSelected[type]['isValidateCouponCount'] = false;
      this.ticketSelected[type]['isValidateCouponCount_Halfsize'] = false;
    }
    this.ticketSelected[type].colorCircleUpper = '#282828FF';
    this.ticketSelected[type].colorCircleLower = '#282828FF';
    this.ticketSelected[type].colorCircleRotation = '#50B4F0FF';
    this.ticketSelected[type].colorFontTicket1Top = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket1Center = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket1Bottom = '#FFFFFF';
    this.ticketSelected[type].colorFontTicket2Top = '#000000';
    this.ticketSelected[type].colorFontTicket2Center = '#000000';
    this.ticketSelected[type].colorFontTicket2Bottom = '#000000';
  }

  /**
   * toLocaleUpperCaseColor
   * @param color
   * @returns
   */
  toLocaleUpperCaseColor(color: string): string {
    return color ? color.toLocaleUpperCase() : '';
  }

  /**
   *sortAndFilter
   */
  sortAndFilter() {
    this.isSortFilter = !this.isSortFilter;

    if (!this.isSortFilter) {
      this.resetSortFilter();
      if (this.ticketDisplays && this.ticketDisplays.length) {
        this.headerColumns.forEach(e => {
          this.clearFilter(e.property);
          e.isSortBy = Constant.EMPTY;
        });
        this.selectTicket(this.ticketDisplays[Constant.FIRST_ELEMENT_INDEX], null);
        this.changeDetectorRef.detectChanges();
        this.listDisplay.nativeElement.scrollTop = 0;
      }
    }
  }

  /**
   * resetSortFilter
   */
  resetSortFilter() {
    this.ticketDisplays = _.cloneDeep(this.dataFilterOrderConvert);
    this.resetColumnsSort();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
  }

  /**
   *showPopupSortFilter
   * @param title
   * @param event
   */
  showPopupSortFilter(property: string, event) {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.fetchFilterData(property);
    }
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    let isFiltered = false;
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    let objectTemp = {};
    let listCurrentFilterClone = _.cloneDeep(this.listCurrentFilter);
    for (let filterTmp in listCurrentFilterClone) {
      if (filterTmp == property) {
        isFiltered = true;
        objectTemp[property] = listCurrentFilterClone[filterTmp].filter(e => e.isChecked);
        continue;
      }
      let filter = listCurrentFilterClone[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let mapProperty = [...new Set(listFilterTmp?.map(e => e[property]))];
    let mapChecked = objectTemp[property]?.map(e => e.name);
    if (isFiltered) {
      mapProperty.forEach(e => {
        if (!mapChecked.includes(e)) {
          objectTemp[property].push({
            isChecked: false,
            name: e
          });
        }
      });
      let templ = _.cloneDeep(objectTemp[property]);
      let cnt = 0;
      for (let index = 0; index < templ?.length; index++) {
        if (!mapProperty.includes(templ[index].name)) {
          objectTemp[property].splice(index - cnt, 1);
          cnt++;
        }
      }
      delete listCurrentFilterClone[property];
      listCurrentFilterClone[property] = objectTemp[property];
    } else {
      objectTemp[property] = new Array<any>();
      mapProperty.forEach(e => {
        objectTemp[property].push({
          isChecked: true,
          name: e
        });
      });
      listCurrentFilterClone[property] = objectTemp[property];
    }
    this.listFilterDisplay = _.cloneDeep(listCurrentFilterClone[property]);
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    this.listFilterDisplayOriginal = _.cloneDeep(this.listFilterDisplay);
    // get list memorize checked
    this.controlCheckBoxCheckAllFilter();
  }

  filterData(ticketId?: any) {
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    if (this.listCurrentFilter && Object.keys(this.listCurrentFilter).length) {
      for (let filterTmp in this.listCurrentFilter) {
        let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
      }
    }
    this.ticketDisplays = listFilterTmp;
    this.ticketDisplays.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    if (this.ticketDisplays && this.ticketDisplays.length) {
      if (ticketId) {
        const index = this.ticketDisplays.findIndex(e => e.ticketId == ticketId);
        if (index != -1) {
          this.selectTicket(this.ticketDisplays[index], null);
        } else {
          this.selectTicket(this.ticketDisplays[Constant.FIRST_ELEMENT_INDEX], null);
        }
      } else {
        this.selectTicket(this.ticketDisplays[Constant.FIRST_ELEMENT_INDEX], null);
      }
    } else {
      this.ticketSelected = undefined;
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplay?.every(filter => filter.isChecked);
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.ticketDisplays.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectTicket(this.ticketDisplays[Constant.FIRST_ELEMENT_INDEX], null);
    this.listDisplay.nativeElement.scrollTop = 0;
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
  }

  /**
   * showCustomSort
   */
  showCustomSort() {
    this.isShowPopUpSortFilter = false;

    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts.forEach(e => {
      e.headerName = this.translateService.instant(e.headerName);
    });
    // propertySorts[propertySorts.length - 2].headerName = this.translateService.instant('timetable-editor.display-1');
    // if (this.isDisplay2) {
    //   propertySorts[propertySorts.length - 1].headerName = this.translateService.instant('timetable-editor.display-2');
    // } else {
    //   propertySorts.pop();
    // }
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns?.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }
        // sort
        // this.ticketDisplays.sort(this.dynamicSortMultiple(this.listSorted));
        // this.selectTicket(this.ticketDisplays[Constant.FIRST_ELEMENT_INDEX], null);
        this.filterData();
        // this.listDisplay.nativeElement.scrollTop = 0;
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    const index = this.headerColumns.findIndex(e => e.property == property && e.isFilterBy);
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    // this.headerColumns.find(data => data.property == property).isSortBy = Constant.EMPTY;
    this.isFilter = false;
    delete this.listCurrentFilter[property];
    if (index != -1) {
      this.filterData();
    }
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    // if( this.listFilterDisplay.every(item =>!item.isChecked)) {
    //   return;
    // }
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplay.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
  }

  /**
   * change checked
   * @param index index of option filter
   */
  checkOptionFilter(index: number) {
    this.listFilterDisplay[index].isChecked = !this.listFilterDisplay[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * filterOder
   * @param property
   * @param isFilterFirstTime
   * @returns
   */
  filterOder(property: string, isFilterFirstTime: boolean) {
    // do not filter all
    if (this.listFilterDisplay.every(e => !e.isChecked)) {
      this.listFilterDisplay.forEach(option => {
        option.isChecked = true;
      });
    }
    this.listCurrentFilter[property] = _.cloneDeep(this.listFilterDisplay);
    if (this.listFilterDisplay.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    const isEqual = JSON.stringify(this.listFilterDisplayOriginal) === JSON.stringify(this.listFilterDisplay);
    if (isEqual) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.filterData();
    this.ticketDisplays.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      if (this.ticketDisplays && this.ticketDisplays.length) {
        this.selectTicket(this.ticketDisplays[Constant.FIRST_ELEMENT_INDEX], null);
      } else {
        this.ticketSelected = undefined;
      }
    }
    this.listDisplay.nativeElement.scrollTop = 0;
  }

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter?.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.tickets?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.tickets?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * checkObjectType
   * @param input
   * @returns
   */
  checkObjectType(input) {
    return typeof input == 'object';
  }

  /**
   * convertListTicket
   * @param listTicket
   * @returns
   */
  convertListTicket(listTicket: any) {
    if (!listTicket || !listTicket.length) {
      return;
    }
    listTicket.forEach(e => {
      for (let property in e) {
        if (this.checkObjectType(e[property])) {
          e[property] = this.languageKey == 'en' ? e[property]?.en : e[property]?.ja;
        }
      }
      if (this.languageKey != 'en') {
        e.ticketType = e.ticketType == 'ticket' ? '企画券' : e.ticketType == 'coupon' ? '綴り券' : '';
        e.isReserve = e.isReserve ? 'あり' : '';
      } else {
        e.isReserve = e.isReserve ? 'Yes' : '';
      }
      e.inUse = e.inUse == true ? '使用中' : e.inUse == false ? '未使用' : '';
      e.validityPeriod = this.getValidityPeriod(e);
      e.updateScheduleAt = this.displayScheduleAt(e.updateScheduleAt);
    });

    return listTicket;
  }

  /**
   * setMultipleDate
   * @param event
   * @param input
   * @returns
   */
  setMultipleDate(event: any, input: string) {
    if (!event) {
      return;
    }
    this.ticketSelected.ticketDetail[input] = event;
    this.ticketSelected.ticketDetail[`isClear${input}`] = false;
    if (this.ticketSelected.ticketDetail[input]) {
      this.ticketSelected.ticketDetail[`isValidate${input}`] = this.ticketSelected.ticketDetail[input]?.length > 30;
    }
  }

  /**
   * getMultipleDate
   * @param input
   * @returns
   */
  getMultipleDate(input: any) {
    if (!input || !input.length) {
      return;
    }
    return input.map(date => `${date}`).join(', ');
  }

  /**
   * clearDatePicker
   * @param input
   */
  clearDatePicker(input: string) {
    this.ticketSelected.ticketDetail[input] = null;
    this.ticketSelected.ticketDetail[`isClear${input}`] = true;
    this.ticketSelected.ticketDetail[`isValidate${input}`] = false;
  }

  /**
   * getDisableActivateWeekdays
   * @param enableActivateWeekdays
   * @returns
   */
  getDisableActivateWeekdays(enableActivateWeekdays: any) {
    const DAY_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    if (!enableActivateWeekdays || !enableActivateWeekdays.length) {
      return DAY_OF_WEEK;
    }
    return DAY_OF_WEEK.filter(e => !enableActivateWeekdays.includes(e));
  }

  /**
   * checkDates
   */
  checkDates() {
    const WEEKDAYS = { sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6 };
    const dayOfWeek = this.ticketSelected.ticketDetail.enableActivateWeekdays.map(weekday => WEEKDAYS[weekday]);
    if (this.ticketSelected.ticketDetail.enableOn && this.ticketSelected.ticketDetail.enableOn.length) {
      this.ticketSelected.ticketDetail.enableOn.forEach(e => {
        const date = new Date(e);
        if (dayOfWeek.includes(date.getDay())) {
          this.ticketSelected.ticketDetail.enableOn = this.ticketSelected.ticketDetail.enableOn.filter(item => item !== e);
        }
      });
      if (this.ticketSelected.ticketDetail?.enableOn?.length) {
        this.ticketSelected.ticketDetail[`isValidateenableOn`] = this.ticketSelected.ticketDetail.enableOn.length > 30;
      } else {
        this.ticketSelected.ticketDetail[`isValidateenableOn`] = false;
      }
    }
    if (this.ticketSelected.ticketDetail.disableOn && this.ticketSelected.ticketDetail.disableOn.length) {
      this.ticketSelected.ticketDetail.disableOn.forEach(e => {
        const date = new Date(e);
        if (!dayOfWeek.includes(date.getDay())) {
          this.ticketSelected.ticketDetail.disableOn = this.ticketSelected.ticketDetail.disableOn.filter(item => item !== e);
        }
      });
      if (this.ticketSelected.ticketDetail?.disableOn?.length) {
        this.ticketSelected.ticketDetail[`isValidatedisableOn`] = this.ticketSelected.ticketDetail.disableOn.length > 30;
      } else {
        this.ticketSelected.ticketDetail[`isValidatedisableOn`] = false;
      }
    }
  }

  /**
   * convertLanguagesToLanguageStringArray
   * @param languages
   * @returns
   */
  convertLanguagesToLanguageStringArray(languages: Array<any>): Array<string> {
    return this.languages?.map(e => e.translation_language_code);
  }

  /**
   * filterPropertyByLanguages
   * @param note
   * @param languages
   * @returns
   */
  filterPropertyByLanguages(property: any, languages: any[]): any {
    let languagesMap = languages?.map(e => e?.translation_language_code);
    const filtered: any = {};
    if (property) {
      languagesMap.forEach(key => {
        if (Object.keys(property).includes(key)) {
          filtered[key] = property[key];
        } else {
          filtered[key] = '';
        }
      });
      return filtered;
    } else {
      return property;
    }
  }

  /**
   * decimalValidator
   * @returns
   */
  decimalValidator(input): boolean {
    if (!input) {
      return true;
    }
    return /^\d+$/.test(input);
  }

  /**
   * showCalendar
   */
  showCalendar(property: any): void {
    this[property] = !this[property];
  }

  /**
   * changeDate
   * @param e
   * @returns
   */
  changeDate(e: any) {
    if (!e) {
      return;
    }
    this.date = e;
    this.ticketSelected.ticketDetailUpdate['isValidateUpdateScheduleAt'] = false;
  }

  /**
   * checkChangeCategory
   * @param input
   * @returns
   */
  checkChangeCategory(input: string) {
    if (!input) {
      return;
    }
    const type = this.isChooseDetailUpdate ? 'ticketDetailUpdate' : 'ticketDetail';

    //Adult
    if (input == '0') {
      this.ticketSelected[type].referencePriceAdult = null;
      this.ticketSelected[type]['isValidateReferencePriceAdult'] = false;
      this.ticketSelected[type]['isValidateReferencePriceAdultMinMax'] = false;
    } else {
      this.ticketSelected[type][`priceLabelCustom${input}`] = null;
      this.ticketSelected[type][`isValidatePriceLabelCustom${input}`] = false;
      this.ticketSelected[type][`isValidatePriceLabelCustom${input}MinMax`] = false;
      this.ticketSelected[type][`referencePriceCustom${input}`] = null;
      this.ticketSelected[type][`isValidateReferencePriceCustom${input}`] = false;
      this.ticketSelected[type][`isValidateReferencePriceCustom${input}MinMax`] = false;
    }
  }

  /**
   * setDefaultIsReserve
   * @param type
   */
  setDefaultIsReserve(type: string) {
    this.ticketSelected[type].reservationAvailablePeriod = null;
    this.ticketSelected[type].reservationAvailablePeriodUnit = 'days';
    this.ticketSelected[type]['isValidateReservationAvailablePeriod'] = false;
    this.ticketSelected[type]['isValidateReservationAvailablePeriod-d'] = false;
    this.ticketSelected[type]['isValidateReservationAvailablePeriod-m'] = false;
    this.ticketSelected[type].reservationDeadline = null;
    this.ticketSelected[type].reservationDeadlineUnit = 'days';
    this.ticketSelected[type]['isValidateReservationDeadline'] = false;
    this.ticketSelected[type]['isValidateReservationDeadline-D'] = false;
    this.ticketSelected[type]['isValidateReservationDeadline-m'] = false;
    this.ticketSelected[type].reservationDisplayStart = null;
    this.ticketSelected[type].reservationDisplayStartUnit = 'days';
    this.ticketSelected[type]['isValidateReservationDisplayStart'] = false;
    this.ticketSelected[type]['isValidateReservationDisplayStart-D'] = false;
    this.ticketSelected[type]['isValidateReservationDisplayStart-m'] = false;
    this.ticketSelected[type].availableActiveStartTime = null;
    this.ticketSelected[type]['isValidateAvailableActiveStartTime'] = false;
    this.ticketSelected[type].availableShowEndTime = null;
    this.ticketSelected[type]['isValidateAvailableShowEndTime'] = false;
    this.ticketSelected[type].enableActivateWeekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
      'holiday'
    ];
  }

  /**
   * getReservation
   * @param ticket
   * @returns
   */
  getReservationDisplayStart(ticket: any, input: string): string {
    if (Helper.isEmpty(ticket[`${input}`]) || !ticket[`${input}Unit`]) {
      return '';
    }
    let reservationDisplayStart: string = '';
    if (ticket[`${input}Unit`] == 'days') {
      if (ticket[input] == 0) {
        reservationDisplayStart = '予約利用日当日から表示可能';
      } else {
        reservationDisplayStart = `予約利用日の${ticket[`${input}`]}日前から表示可能`;
      }
    } else if (ticket[`${input}Unit`] == 'minutes') {
      reservationDisplayStart = `予約利用日時の${ticket[`${input}`]}分前から表示可能`;
    }
    return reservationDisplayStart;
  }

  /**
   * getReservation
   * @param ticket
   * @returns
   */
  getReservationDeadline(ticket: any, input: string): string {
    if (Helper.isEmpty(ticket[`${input}`]) || !ticket[`${input}Unit`]) {
      return '';
    }
    let reservationDeadline: string = '';
    if (ticket[`${input}Unit`] == 'days') {
      reservationDeadline = `予約利用日の${ticket[`${input}`]}日前まで予約可能`;
    } else if (ticket[`${input}Unit`] == 'minutes') {
      reservationDeadline = `予約利用日時の${ticket[`${input}`]}分前まで予約可能`;
    }
    return reservationDeadline;
  }

  /**
   * getReservationPeriod
   * @param ticket
   * @param input
   * @returns
   */
  getReservationPeriod(ticket: any, input: string): string {
    if (Helper.isEmpty(ticket[`${input}`]) || !ticket[`${input}Unit`]) {
      return '';
    }
    let type;
    if (ticket[`${input}Unit`] == 'days') {
      type = '日';
    } else if (ticket[`${input}Unit`] == 'months_with_day') {
      type = 'ヶ月（同日）';
    } else if (ticket[`${input}Unit`] == 'months_without_day') {
      type = 'ヶ月（末日）';
    }
    return `${ticket[`${input}`]}${type}先まで予約可能`;
  }

  /**
   * convertProperty
   * @param prop
   * @returns
   */
  convertProperty(prop) {
    if (!prop) {
      return;
    }
    if (!Object.keys(prop).length) {
      prop = null;
    } else {
      if (Object.values(prop).every(e => e == '' || e == undefined || e == null)) {
        prop = null;
      } else {
        this.languages.forEach(e => {
          if (!prop[e.translation_language_code] || !prop.hasOwnProperty(e.translation_language_code)) {
            prop[e.translation_language_code] = '';
          }
        });
      }
    }
    return prop;
  }

  /**
   * getIsReserve
   * @param input
   * @returns
   */
  getIsReserve(input: any) {
    if (!input) {
      return this.languageKey == 'en' ? 'No' : 'なし';
    } else {
      return this.languageKey == 'en' ? 'Yes' : 'あり';
    }
  }

  /**
   * restrictToNumbers
   * @param type
   * @param input
   */
  restrictToNumbers(type: string, input: string): void {
    if (Helper.isEmpty(this.ticketSelected[type][input])) {
      return;
    }
    this.ticketSelected[type][input] = this.ticketSelected[type][input].toString().replace(/[^\u0030-\u0039]/g, '');
  }
}
